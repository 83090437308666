import { Text, Image, Flex } from '@chakra-ui/react';
import CommonModal from '../UI/CommonModal';
import okxIcon from './okx.png';
import unisatIcon from './unisat.png';
import metamaskIcon from './metamask.png';
import tokenpocketIcon from './tokenpocket.png';
import bitgetIcon from './bitget.png';
import styles from './index.module.scss';
import { useZuStore } from '../../hooks/useZuStore';
import { getOkxInfo, getUnisatInfo } from '../../hooks/wallets/btcWallets';
import { useMessage } from '../../hooks/useMessage';
import { useIsMobile } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConnected: (walletAddress: string) => Promise<void>;
};

export default ({ isOpen, onClose, onConnected }: Props) => {
  const zuStore = useZuStore();
  const message = useMessage();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const query = new URLSearchParams(window.location.search);
  const type: 'btc' | 'eth' = query.get('type') === 'btc' ? 'btc' : 'eth';
  const isBtc = type === 'btc';

  const handleConnectOkx = async () => {
    const walletInfo = await getOkxInfo();
    if (!walletInfo && isMobile) {
      const encodedDappUrl = encodeURIComponent(window.location.href);
      const deepLink = 'okx://wallet/dapp/url?dappUrl=' + encodedDappUrl;
      const encodedUrl = 'https://www.okx.com/download?deeplink=' + encodeURIComponent(deepLink);
      window.open(encodedUrl, '_blank');
      return;
    }
    if (!walletInfo) {
      message.error(t('Please install okx Wallet', 'Please install okx Wallet'));
      return;
    }
    try {
      const result = await walletInfo.connect();
      zuStore.setBtcConnectorInfo(walletInfo);
      zuStore.setUserState({
        accountAddress: result,
        nativeBalance: '0'
      });
      zuStore.setWalletInfo({
        walletName: 'okx',
        walletType: 'btc'
      });
      onClose();
      await onConnected(result);
    } catch (error) {
      message.error('Error connecting okx Wallet');
    }
  };
  const handleConnectUnisat = async () => {
    const walletInfo = await getUnisatInfo();
    if (!walletInfo) {
      message.error(t('Please install Unisat Wallet', 'Please install Unisat Wallet'));
      return;
    }
    try {
      const result = await walletInfo.connect();
      console.log('result', result);
      const balance = await walletInfo.getBalance();
      zuStore.setBtcConnectorInfo(walletInfo);
      zuStore.setUserState({
        accountAddress: result,
        nativeBalance: balance
      });
      zuStore.setWalletInfo({
        walletName: 'unisat',
        walletType: 'btc'
      });
      onClose();
      await onConnected(result);
    } catch (error) {
      console.log('error', error);

      message.error(t('Error connecting Unisat Wallet', 'Error connecting Unisat Wallet'));
    }
  };
  const handleConnectMetamask = async () => {
    const result = await zuStore.providerState.connect();
    if (result) {
      zuStore.setIsConnected(true);
      zuStore.setUserState({
        accountAddress: result,
        nativeBalance: '0'
      });
      zuStore.setWalletInfo({
        walletName: 'metamask',
        walletType: 'eth'
      });
      onClose();
      await onConnected(result);
    }
  };
  const handleEthOkxConnect = async () => {
    const result = await zuStore.providerState.connect();
    if (result) {
      zuStore.setIsConnected(true);
      zuStore.setUserState({
        accountAddress: result,
        nativeBalance: '0'
      });
      zuStore.setWalletInfo({
        walletName: 'okx',
        walletType: 'eth'
      });
    }
    onClose();
    console.log('result', result);
    await onConnected(result);
  };

  return (
    <CommonModal isOpen={isOpen} onClose={onClose} title={t('Connect with', 'Connect with')}>
      {isBtc && (
        <Flex width="100%" flexDirection="column" gap="20px">
          <Text fontWeight={600}>Bitcoin</Text>
          <Flex className={styles.walletBtn} onClick={handleConnectOkx}>
            <Image src={okxIcon} width="46px" height="46px" />
            <Text fontWeight={600}>OKX Wallet</Text>
          </Flex>
          <Flex className={styles.walletBtn} onClick={handleConnectUnisat}>
            <Image src={unisatIcon} width="46px" height="46px" />
            <Text fontWeight={600}>Unisat Wallet</Text>
          </Flex>
        </Flex>
      )}

      {!isBtc && (
        <Flex width="100%" flexDirection="column" gap="20px">
          <Text fontWeight={600}>Ethereum</Text>
          <Flex className={styles.walletBtn} onClick={handleConnectMetamask}>
            <Image src={metamaskIcon} width="46px" height="46px" />
            <Text fontWeight={600}>Metamask</Text>
          </Flex>
          <Flex className={styles.walletBtn} onClick={handleConnectMetamask}>
            <Image src={okxIcon} width="46px" height="46px" />
            <Text fontWeight={600}>OKX Wallet</Text>
          </Flex>
          <Flex className={styles.walletBtn} onClick={handleConnectMetamask}>
            <Image src={tokenpocketIcon} width="46px" height="46px" />
            <Text fontWeight={600}>TokenPocket</Text>
          </Flex>
          <Flex className={styles.walletBtn} onClick={handleConnectMetamask}>
            <Image src={bitgetIcon} width="46px" height="46px" />
            <Text fontWeight={600}>Bitget Wallet</Text>
          </Flex>
        </Flex>
      )}
    </CommonModal>
  );
};
