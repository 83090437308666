import { Flex } from '@chakra-ui/react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
// import downloadIcon from '../../assets/image/dowload.png';
import { useTranslation } from 'react-i18next';
import { Title } from './Title';
import { Staked } from './Staked';
import { Balance } from './Balance';
import { Pool } from './Pool';

export default function Stake() {
  const { t } = useTranslation();
  return (
    <Flex minHeight={{ base: 'calc(100vh - 60px)', md: 'calc(100vh - 80px)' }}>
      <MainContainer>
        <Flex
          justifyContent="center"
          alignItems="start"
          mb="100px"
          mt={{ base: '40px', md: '100px' }}
          flexDirection="column">
          <Title />
          <Flex
            justifyContent="space-between"
            width={'100%'}
            padding="15px"
            paddingBottom={{ base: '30px', md: '0px' }}
            flexDirection={{ base: 'column', md: 'row' }}
            gap="30px">
            <Staked />
            <Balance />
          </Flex>
          <Pool />
        </Flex>
      </MainContainer>
    </Flex>
  );
}
