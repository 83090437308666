import { Flex } from '@chakra-ui/react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
// import { CommonButton } from '../../components/UI/CommonButton';
// import downloadIcon from '../../assets/image/dowload.png';
// import { MdOutlineFileDownload } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Title } from './Title';
import { BtcSection } from './BtcSection';
import { EthSection } from './EthSection';
import styles from './index.module.scss';

export default function Claim() {
  const { t } = useTranslation();
  return (
    <Flex minHeight={{ base: 'calc(100vh - 60px)', md: 'calc(100vh - 80px)' }}>
      <MainContainer className={styles.container}>
        <Flex
          justifyContent="center"
          gap="60px"
          alignItems={{ base: 'center', md: 'start' }}
          mb={4}
          mt={{ base: '40px', md: '100px' }}
          flexDirection={{ base: 'column', md: 'row' }}
          flexWrap={{ base: 'nowrap', md: 'wrap' }}>
          <Title />
          <BtcSection />
          <EthSection />
        </Flex>
      </MainContainer>
    </Flex>
  );
}
