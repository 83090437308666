import styles from './Home.module.scss';
import { Flex, Text, Box } from '@chakra-ui/react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import Lottie from 'react-lottie';
import * as factoryAnimation from '../../assets/lottie/factory.json';
import * as secAnimation from '../../assets/lottie/SEC.json';
import * as scalabilityAnimation from '../../assets/lottie/scalability.json';
import * as liquidityAnimation from '../../assets/lottie/liquidity.json';

import { ReactComponent as ComponentsIcon } from '../../assets/image/components.svg';
import { ReactComponent as ComponentsMobileIcon } from '../../assets/image/components-mobile.svg';

import { useWindowDimensions } from '../../utils/utils';
import { BREAKPOINT } from '../../utils/const';
import classNames from 'classnames';
import Twits from './components/Twitter';
import { benifitItems, initiativeItems, socialItems } from './components/cards';
import { BenifitCard } from '../../components/UI/BenifitCard';
import { ReactComponent as InsfiIcon } from '../../assets/image/INSFI.svg';
// import FairLaunch from './components/FairLaunch';
import { useCountdown } from '../../hooks/useCountdown';
import { useEffect, useState } from 'react';
import { InitiativeCard } from '../../components/UI/InitiativeCard';
import { SocialCard } from '../../components/UI/SocialCard';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

export default function Home() {
  // infinite loop
  const animationLoopTimer = useCountdown(new Date('2030-01-24T00:00:00.000Z').getTime());

  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isMobile = width < BREAKPOINT;
  const { t } = useTranslation();

  const gotoTwitter = () => {
    window.open('https://twitter.com/');
  };
  const gotoTelegram = () => {
    window.open('https://t.me/');
  };

  const getDefaultOption = (anim: any) => ({
    loop: true,
    autoplay: true,
    animationData: anim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  });

  const animationList = [
    getDefaultOption(liquidityAnimation),
    getDefaultOption(secAnimation),
    getDefaultOption(scalabilityAnimation)
  ];

  const [count, setCount] = useState(0);
  useEffect(() => {
    const handle = setInterval(() => {
      console.log(count);
      setCount(count + 1);
    }, 3 * 1000);
    return clearInterval(handle);
  }, []);

  const componentAnimation = animationList[Math.floor((animationLoopTimer as any).seconds / 3) % 3];

  return (
    <div className={classNames(styles.container)} style={{ overflow: 'clip' }}>
      <MainContainer>
        <Flex direction="column" position="relative" alignItems="center">
          <Flex className={styles.bannerWrap}>
            {/* <FairLaunch className={styles.fadeInBox1} /> */}

            <Flex
              maxWidth="100%"
              direction="column"
              position="relative"
              alignItems="center"
              className={styles.fadeInBox2}>
              <Box position="absolute" zIndex={1} top={{ md: '-100px', base: '-10px' }}>
                {isMobile ? (
                  <Lottie options={getDefaultOption(factoryAnimation)} width="100%" />
                ) : (
                  <Lottie options={getDefaultOption(factoryAnimation)} height={1000} width={1000} />
                )}
              </Box>
              <Flex
                zIndex={2}
                width={{ base: '60%', md: '100%' }}
                justifyContent="center"
                gap={8}
                mt={{ base: '100px', md: '150px' }}>
                <InsfiIcon />
              </Flex>
              <Flex zIndex={2} width={{ base: '60%', md: '100%' }} justifyContent="center" gap={8} mt={8}>
                <Text fontSize="16px" fontWeight={400} maxWidth={600} textAlign="center">
                  {t('Compound Inscription, all the time!', 'Compound Inscription, all the time!')}
                </Text>
              </Flex>
              <Flex zIndex={2} width="100%" justifyContent="center" gap={8} mt={{ base: '100px', md: '550px' }}>
                <Text color="#3856cc" fontSize="40px" fontWeight={700} maxWidth={600} textAlign="center">
                  {t('What is InsFi', 'What is InsFi')}
                </Text>
              </Flex>
              <Flex
                zIndex={2}
                width={{ base: '100%', md: '100%' }}
                justifyContent="center"
                gap={8}
                mt={{ base: '20px', md: '20px' }}>
                <Text fontSize="14px" fontWeight={400} maxWidth={600} textAlign="center">
                  <Trans i18nKey={'insfi-introduction'}>
                    The InsFi protocol, a first-of-its-kind yield aggregation protocol for inscription assets in
                    blockchain, offers a unique on-chain financial strategy for managing inscription series assets.
                    Utilizing advanced decentralized strategies, it enables investors to secure stable, long-term
                    returns by staking InsFi whitelisted inscription assets, simultaneously bolstering the on-chain
                    market's liquidity.
                  </Trans>
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex className={styles.benifitWrap} flexWrap="wrap" gap="60px">
            <Flex flexDirection="column" maxWidth={{ base: '100%', md: '350px' }}>
              <Lottie options={componentAnimation} height={400} width={400} />
            </Flex>

            <Flex
              gap="10px"
              borderRadius="16px"
              alignItems="start"
              justifyContent="center"
              flexDirection="column"
              width={{ base: '100%', md: '350px' }}
              mt={{ base: '0', md: '100px' }}>
              <Text
                color="#3856cc"
                fontSize={{ md: '40px', base: '32px' }}
                fontWeight={700}
                width={{ md: 600, base: '100%' }}
                textAlign="left"
                pl={{ base: 0, md: '30px' }}>
                {t('Highlights', 'Highlights')}
              </Text>
              {benifitItems.map((item, index) => {
                return <BenifitCard key={index} title={item.title} icon={item.icon} content={item.content} />;
              })}
            </Flex>
          </Flex>

          <Flex
            className={classNames(styles.qaWrap, styles.fadeInBox1)}
            mt={{ base: '0', md: '100px' }}
            width={{ base: '100%', md: 'auto' }}>
            <Text
              color="#3856cc"
              fontSize="50px"
              fontWeight={700}
              maxWidth={{ md: 600, base: '100%' }}
              textAlign="center">
              {t('How it works', 'How it works')}
            </Text>
            {isMobile ? (
              <ComponentsMobileIcon preserveAspectRatio="initial" width="100%" height="650px" />
            ) : (
              <ComponentsIcon preserveAspectRatio="initial" width="800px" />
            )}
          </Flex>

          <Flex className={classNames(styles.qaWrap, styles.fadeInBox1)} mt={{ base: '0', md: '100px' }}>
            <Text color="#3856cc" fontSize="50px" fontWeight={700} maxWidth={600} textAlign="center">
              {t('Initiatives', 'Initiatives')}
            </Text>
            <Flex flexWrap="wrap" gap="10px" justifyContent="center">
              {initiativeItems.map((item) => {
                return <InitiativeCard key={item.title} title={item.title} icon={item.icon} content={item.content} />;
              })}
            </Flex>
          </Flex>

          <Flex
            className={classNames(styles.socialWrap, styles.fadeInBox1)}
            mt={{ base: '0', md: '100px' }}
            rowGap="20px">
            <Text color="#3856cc" fontSize="50px" fontWeight={700} maxWidth={600}>
              {t('Join us', 'Join us')}
            </Text>
            <Flex
              width="100%"
              minHeight="400px"
              borderRadius="16px"
              alignItems="center"
              justifyContent="center"
              rowGap="20px"
              flexWrap="wrap">
              <Flex flexWrap="wrap" gap="10px" justifyContent="center" width={{ md: '400px', base: '100%' }}>
                {socialItems.map((item) => {
                  return <SocialCard key={item.title} title={item.title} icon={item.icon} link={item.link} />;
                })}
              </Flex>
              <Box width={{ md: 'auto', base: '100%' }} flex={1}>
                <Twits />
              </Box>
            </Flex>
          </Flex>

          <Flex width="100%" justifyContent="center" mb="50px">
            contact@insfi.xyz
          </Flex>
          {/* <Flex className={classNames(styles.qaWrap, styles.bg3, styles.fadeInBox1)}>
            <Text fontSize="50px" fontWeight={700} maxWidth={600} textAlign="center">
              INSFI Product
            </Text>
            <Flex flexWrap="wrap" gap="10px" justifyContent="center">
              {cardItems.map((item) => {
                return <Card key={item.title} title={item.title} icon={item.icon} content={item.content} />;
              })}
            </Flex>
          </Flex> */}
          {/* 
          <Flex className={styles.qaWrap} maxWidth="680px" alignItems="center">
            <Text color="#3856cc" fontSize="50px" fontWeight={700} maxWidth={600} textAlign="center">
              Raodmap
            </Text>
            <VerticalTimeline />
          </Flex> */}

          {/* <Flex className={styles.socialWrap} flexWrap="wrap">
            <Flex flexDirection="column">
              <Text fontSize="50px" fontWeight={700} maxWidth={600} textAlign="center">
                FOLLOW US
              </Text>
              <Flex gap={4} mt={8}>
                <Image src={TwitterIcon} onClick={gotoTwitter} width="64px" height="64px" />
                <Image src={TelegramIcon} onClick={gotoTelegram} width="64px" height="64px" />
              </Flex>
            </Flex>

            <Flex width="400px" minHeight="400px" borderRadius="16px" alignItems="center" justifyContent="center">
              <Twits />
            </Flex>
          </Flex> */}

          {/* <Flex flexDirection="column" alignItems="center">
            <Text fontSize={{ base: '34px', md: '46px' }} fontWeight={700} mb="40px">
              SPONSORED BY
            </Text>
            <Flex className={styles.sponsorWrap} flexWrap="wrap">
              <Image src={SponsorIcon1} aspectRatio="initial" width={{ base: '150px', md: '200px' }} />
              <Image src={SponsorIcon2} aspectRatio="initial" width={{ base: '150px', md: '200px' }} />
              <Image src={SponsorIcon3} aspectRatio="initial" width={{ base: '150px', md: '200px' }} />
              <Image src={SponsorIcon4} aspectRatio="initial" width={{ base: '150px', md: '200px' }} />
            </Flex>
          </Flex> */}
        </Flex>
      </MainContainer>
    </div>
  );
}
