import { Box, Flex, Image } from '@chakra-ui/react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import styles from './Twitter.module.scss';
import React, { useEffect } from 'react';
import twitterIcon from '../../../assets/social/twitter.png';

const Twits = () => {
  const ref = React.useRef(null);

  useEffect(() => {
    const element = ref.current;
    console.log(ref.current);
    if (!!element) {
      const iframes = document.querySelectorAll('iframe');
      console.log(iframes.length);
      if (iframes && iframes.length > 0) {
        for (let index = 0; index < iframes.length; index++) {
          const iframe = iframes[index];
          var innerDoc = iframe.contentDocument;

          console.log('innerDoc is: ', innerDoc);
          if (innerDoc) {
            var htmlContent = innerDoc.documentElement.querySelectorAll('#app > div > div > div > article');
            console.log('htmlContent is: ', htmlContent);
            if (htmlContent) {
            }
          }
          iframe.style.backgroundColor = 'blue';
        }
      }
    }
  }, [ref]);

  const handleTwitterClick = () => {
    window.open('https://twitter.com/InsFi_');
  };

  return (
    <Flex
      className={styles.container}
      width="100%"
      height={{ md: '250px', base: 'auto' }}
      minHeight="250px"
      flexDirection={{ md: 'row', base: 'column' }}
      marginTop={{ md: '0', base: '20px' }}
      justifyContent="center"
      gap={4}
      bgColor="#091542"
      // backgroundImage={twitterIcon}
      // backgroundRepeat="no-repeat"
      // backgroundPosition="center"
      minWidth="290px"
      borderRadius="20px"
      overflow="scroll"
      padding="20px">
      <Flex className={styles.defaultTwitter} zIndex={1}>
        <Image src={twitterIcon} onClick={handleTwitterClick} />
      </Flex>
      <Box zIndex={2}>
        <TwitterTweetEmbed
          options={{ theme: 'dark', height: '100px', hideMedia: 'hidden' }}
          tweetId="1753038553419038813"
        />
      </Box>
    </Flex>
  );
};

export default Twits;
