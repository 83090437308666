import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './components/Header';
import { useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import classNames from 'classnames';

function App() {
  const location = useLocation();
  const path = location.pathname;
  return (
    <div className={classNames(styles.container, styles.bg)}>
      <Header />
      <Outlet />
    </div>
  );
}

export default App;
