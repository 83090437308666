import React, { useEffect, useState } from 'react';
import { Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import styles from './LangButton.module.scss';

const langs = [
  { key: 'en', label: 'English' },
  { key: 'zh', label: '简体中文' },
  { key: 'ja', label: '日本語' },
  { key: 'ko', label: '한국의' },
  { key: 'es', label: 'Español' },
  { key: 'ru', label: 'Русский' },
  { key: 'hi', label: 'हिन्दी' },
  { key: 'ar', label: 'عربي' },
  { key: 'ms', label: 'Bahasa Melayu' },
  { key: 'vi', label: 'tiếng Việt' },
  { key: 'id', label: 'Bahasa Indonesia' },
  { key: 'th', label: 'Thai' }
];

const defaultLang = langs[0];

const LangButton = () => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState<any>(defaultLang);

  useEffect(() => {
    const lang = i18n.resolvedLanguage || defaultLang.key;

    setCurrentLang(langs.find((item) => item.key === lang));
  }, [i18n.resolvedLanguage]);

  const handleLangChange = (key: string) => {
    i18n.changeLanguage(key);
  };
  return (
    <Menu placement="bottom-end">
      <MenuButton>
        <Flex className={styles.button}>
          <img className={styles.buttonImage} src={require('../../../assets/image/mutliLang.png')} alt={'mutliLang'} />
          {currentLang.label}
        </Flex>
      </MenuButton>
      <MenuList className={styles.menuList} border={'none'}>
        {langs.map((item) => (
          <MenuItem className={styles.menuItem} key={item.key} onClick={() => handleLangChange(item.key)}>
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LangButton;
