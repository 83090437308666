import { ethers } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { truncateNumber } from './utils';

export const btcInvestReport = [
    {
        address: 'bc1p4rldu7aywuha9kl0l7ed6yt5zfr7szn72w8tafuftuhnrakg429q4u5f8a',
        sum: '0.008'
    },
];

export const ethInvestReport = [
    {
        address: '0xBE1fAA85066E091e97a976a89c55EA07e450aEed',
        sum: '4.9842'
    },
    {
        address: '0xf802A285fF269B0A0295488aec5D2a581C4B55f3',
        sum: '4.8923'
    },
    {
        address: '0x12bB47380C4F8b24EfC1A9c0991e8d45778d589B',
        sum: '4.8072'
    },
    {
        address: '0x8BfeAbcFd17887e1d4cff93cD38324E91B375d79',
        sum: '4.7972'
    },
    {
        address: '0x4622244bc11eeDDD409B330D181E9ade7C89D80D',
        sum: '4.744788498328666073'
    },
    {
        address: '0x90359B3E7Ea6A65d75e655FBC80aC301D7f2d2A6',
        sum: '4.6596'
    },
    {
        address: '0xa0A7b4aE935E2010A43953452922c200900F7598',
        sum: '4.6582'
    },
    {
        address: '0xa15bb68f7bF89Aa048E7dce8558Fa1f57cF215e2',
        sum: '4.5172'
    },
    {
        address: '0xA689574Fd2BF0AD306b84658dA39373dcc16CC19',
        sum: '4.4032'
    },
    {
        address: '0xd606AC69dAB94260Ed34F490810ca036981887D0',
        sum: '4.2271'
    },
    {
        address: '0xd4136f3AF87451e17C7fA2D872A792888AC90C55',
        sum: '4.1902'
    },
    {
        address: '0x67E1246Cb1EabD7240232EEdf6696500850869F1',
        sum: '4.1672'
    },
    {
        address: '0xE20496706103e8119d9dddb9f2fd5be1872879bE',
        sum: '4.0482'
    },
    {
        address: '0x64ec3eCEb81643b28Dd1AB7E87b4910E4C50f9c7',
        sum: '3.9772'
    },
    {
        address: '0x8160556aD97b574Ab8c68C4976076450938bad8D',
        sum: '3.9475'
    },
    {
        address: '0x82cF23834BAdF3355c49870F1e4e47D853317076',
        sum: '3.8072'
    },
    {
        address: '0xC558e80d6fBe8605e9bF0db20eD3398e5e91D092',
        sum: '3.5567'
    },
    {
        address: '0xEB92A8A80b3ebcBE033DcffdEAEe35e126848fea',
        sum: '3.4972'
    },
    {
        address: '0x74B637f9Be08155A288553C9A9b5F56A5f52E6aD',
        sum: '3.2492'
    },
    {
        address: '0x65c508601f076255e6986d56058e293aF2Fd14C4',
        sum: '3.2262'
    },
    {
        address: '0x666d0a10D65CbEaaCf32c7f5b45a34e51B0A8ED3',
        sum: '2.4904'
    },
    {
        address: '0x926afcb01cB30Eec328b835CBFe8f542d183bbf6',
        sum: '2.3802'
    },
    {
        address: '0x76c11330315fbE2BcefEb0437FD0A5511BFFc845',
        sum: '2.2592'
    },
    {
        address: '0x8ee7a93dB8811c9325e06f4ffa61b4ce4AdDdB9F',
        sum: '2.1672'
    },
    { address: '0x1feF320E21794BF6486a3ccBf20FdAb286A9e741', sum: '2.0' },
    {
        address: '0xF09Bf2cd426a6C14E712d6CC3b339910125AEBbD',
        sum: '1.5422'
    },
    {
        address: '0x898C94E54B7C59e8A08186CD34D0556c57B25865',
        sum: '1.3912'
    },
    {
        address: '0xa33f2fadd7Ae0bdaF24C423Cc0c428914A8dd6a1',
        sum: '1.27247'
    },
    {
        address: '0x890303b2db916c56f3ff4BEADF786Ce6c0Caf01b',
        sum: '1.1354'
    },
    {
        address: '0x2b9BF6a8aCab2b9b91f7B66091dFC170bC075517',
        sum: '0.6778'
    },
    { address: '0xA44a3DFA8CbE786bF704d3577B736a567c12F8f7', sum: '0.5' },
    {
        address: '0x7C56d25344eAD7e67608ac6228449458d9421a1F',
        sum: '0.49'
    },
    { address: '0xC4725eAa893603E03B374A4218E29d48Be3b129c', sum: '0.2' },
    {
        address: '0xf1a61eFfb317d24543B099E3dD6675D6dEEa09B1',
        sum: '0.15'
    },
    {
        address: '0x327A2094852cc8B07b65230568A4fB9921Eb180c',
        sum: '0.15'
    },
    {
        address: '0x294D031E23Bb0B0c74a61A7C46489aA036a7624a',
        sum: '0.11'
    },
    { address: '0x248EA1b6dFB806C279a7CCa4ee8f39F4C128FE46', sum: '0.1' }
];

// total 229076.47 USD value at price of 2216
const investedTotal = 103.373858498328666073;

const INFI_REWARD_AMOUNT = 5_0000_0000;


export const getEthReward = (address: string) => {
    const investedItem = ethInvestReport.find(item => item.address.toLowerCase() === address.toLowerCase());
    if (!investedItem) return 0;
    const investedTotalBi = parseEther(String(investedTotal));
    const investedSumBi = parseEther(investedItem.sum);

    const reward = investedSumBi.mul(ethers.constants.WeiPerEther).div(investedTotalBi).mul(INFI_REWARD_AMOUNT);
    return truncateNumber(formatEther(reward), 1);
};

// get btc reward
export const getBtcReward = (address: string) => {
    const investedItem = btcInvestReport.find(item => item.address === address);
    if (!investedItem) return 0;
    // only one btc user, invested 300 USD value of btc, in return the reward is 0.1% of 5 Billion, 500,000,000 * 300 / (300 + 2216 * 103.373858498328666073) = 653946 INFI
    return 653946;
};
 