import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';
import Home from './pages/Home/Home';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
import Launch from './pages/Launch';
import WhiteList from './pages/WhiteList';
import MediaKit from './pages/MediaKit';

import './i18n';
import Claim from './pages/Claim';
import Stake from './pages/Stake';

declare global {
  interface Window {
    unisat: any;
    okxwallet: any;
    ethereum: any;
  }
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: '/fair-launch',
        element: <Launch />
      },
      {
        path: '/media-kit',
        element: <MediaKit />
      },
      {
        path: '/claim',
        element: <Claim />
      },
      {
        path: '/stake',
        element: <Stake />
      },
      {
        path: '/whitelist',
        element: <WhiteList />
      }
    ]
  }
]);

root.render(
  <React.StrictMode>
    {/* <ColorModeScript initialColorMode={theme.config.initialColorMode} /> */}
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);
