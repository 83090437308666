import React from 'react';
import styles from './index.module.scss';
import { Flex, Text } from '@chakra-ui/react';
import btcIcon from '../../../assets/launch/btc.png';
import btcConnectedIcon from '../../../assets/launch/btcConnected.png';
import ethIcon from '../../../assets/launch/eth.png';
import ethConnectedIcon from '../../../assets/launch/ethConnected.png';
import { CommonButton } from '../../../components/UI/CommonButton';
import { CommonInput } from '../../../components/UI/CommonInput';
// import { useBtcConnectState } from '../../../hooks/useBtcConnect';
import { truncAddress, truncateNumber } from '../../../utils/utils';
import { useContracts } from '../../../hooks/useContracts';
import { useQuery } from '@tanstack/react-query';
import { useMessage } from '../../../hooks/useMessage';
import { formatEther, formatUnits, parseEther, parseUnits } from 'ethers/lib/utils';
import { requestBtcBalance, requestGetBtcInfo } from '../../../utils/api';
import { BigNumber, ethers } from 'ethers';
import { useZuStore } from '../../../hooks/useZuStore';
import { useCountdown } from '../../../hooks/useCountdown';
import { useTranslation } from 'react-i18next';

type Props = {
  type: 'btc' | 'eth';
  onConnectWallet?: () => void;
};

export default ({ type, onConnectWallet }: Props) => {
  let connected = false;
  const zuStore = useZuStore();
  const isConnected = !!zuStore.userState?.token && zuStore.walletInfo?.walletType === type;

  if (isConnected) {
    connected = true;
  }
  if (connected) return <ConnectedSection type={type} />;
  return <DisconnectedSection type={type} onConnectWallet={onConnectWallet} />;
};

const DisconnectedSection = ({ type, onConnectWallet }: Props) => {
  const zuStore = useZuStore();
  const contracts = useContracts();
  const handleConnectWallet = () => {
    zuStore.reset();
    onConnectWallet && onConnectWallet();
  };
  const { t } = useTranslation();
  const btcDonateAddress = process.env.REACT_APP_BTC_IDO_ADDRESS!;

  const totalBtcInvestedQuery = useQuery({
    queryKey: ['totalInvestedBtcQuery'],
    queryFn: () => requestBtcBalance(btcDonateAddress),
    enabled: !!btcDonateAddress
  });
  const totalBtcInvested =
    totalBtcInvestedQuery.isSuccess && !!process.env.REACT_APP_ALLOW_DONATE_BTC ? totalBtcInvestedQuery.data : 0;

  const totalEthInvestedQuery = useQuery({
    queryKey: ['totalInvestedQuery'],
    queryFn: async () => {
      try {
        const provider = ethers.providers.getDefaultProvider('mainnet');
        const balance = await provider.getBalance('0xd13482d0330C63AA00018Bf18105C5dC8Dcee4E4');
        console.log('balance', balance);

        return balance;
      } catch (error) {
        console.log('totalInvestedQuery error', error);
        return BigNumber.from(0);
      }
    },
    refetchInterval: 1000 * 5
  });
  const totalEthInvested = totalEthInvestedQuery.isSuccess ? totalEthInvestedQuery.data : 0;

  return (
    <>
      <Flex flexDirection="column" gap="20px" width="100%">
        <Flex flexDirection="row" gap="20px" justifyContent="space-between" width={{ base: '100%', md: '100%' }}>
          <Flex>
            <img src={type === 'btc' ? btcIcon : ethIcon} alt="btc" className={styles.tokenIcon} />
          </Flex>
          <Flex flexDirection="column">
            <Text fontSize={{ base: '20px', md: '32px' }} fontWeight={700} ml={2} textAlign="right">
              {type === 'btc'
                ? `${truncateNumber(formatUnits(totalBtcInvested, 8))} BTC`
                : `${truncateNumber(formatEther(totalEthInvested))} ETH`}
            </Text>
            <Text fontSize={{ base: '12px', md: '12px' }} ml={2} opacity={0.5} textAlign="right">
              {type === 'btc'
                ? t('Raised (Quantity of BTC)', 'Raised (Quantity of BTC)')
                : t('Raised (Quantity of ETH)', 'Raised (Quantity of ETH)')}
            </Text>
          </Flex>
        </Flex>
        <CommonButton handleClick={handleConnectWallet} name={t('Connect Wallet', 'Connect Wallet')} thick />
      </Flex>
    </>
  );
};

const ConnectedSection = ({ type }: Props) => {
  return type === 'btc' ? <BtcConnectedSection /> : <EthConnectedSection />;
};

const BtcConnectedSection = () => {
  const MIN_INVEST = 0.005;
  const MAX_INVEST = 0.2;
  const btcDonateAddress = process.env.REACT_APP_BTC_IDO_ADDRESS!;
  const startCountdown = useCountdown(new Date('2024-01-24T12:00:00+08:00').getTime());
  const isStarted = startCountdown.difference === 0;
  const zuStore = useZuStore();
  const { accountAddress, nativeBalance } = zuStore.userState!;
  const [inputAmount, setInputAmount] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const message = useMessage();
  const { t } = useTranslation();
  const personQuery = useQuery({
    queryKey: ['person', accountAddress],
    queryFn: () => requestGetBtcInfo(accountAddress),
    enabled: !!accountAddress && !!zuStore.userState?.token
  });

  const invested = personQuery.isSuccess ? personQuery.data.sum_btc : 0;
  const totalInvestedQuery = useQuery({
    queryKey: ['totalInvestedBtcQuery'],
    queryFn: () => requestBtcBalance(btcDonateAddress),
    enabled: !!btcDonateAddress
  });
  const totalInvested =
    totalInvestedQuery.isSuccess && !!process.env.REACT_APP_ALLOW_DONATE_BTC ? totalInvestedQuery.data : 0;

  const hanldeInvest = async () => {
    if (Number(inputAmount) < MIN_INVEST) {
      message.error(t('min contribution BTC', 'Min contribution is {{MIN_INVEST}} BTC', { MIN_INVEST }));
      return;
    }
    if (Number(inputAmount) + Number(formatUnits(invested, 8)) > MAX_INVEST) {
      message.error(t('max contribution BTC', 'Max contribution is {{MAX_INVEST}} BTC', { MAX_INVEST }));
      return;
    }

    try {
      if (!zuStore.btcConnector) {
        message.error(t('BTC connector is not ready', 'BTC connector is not ready'));
        return;
      }
      const result = await zuStore.btcConnector.sendBitcoin(btcDonateAddress, parseUnits(inputAmount, 8).toNumber());
      console.log('result', result);
      message.success(t('donate success', 'Donate {{inputAmount}} success', inputAmount));
    } catch (error) {
      console.log('error', error);
      message.error(t('Donate failed', 'Donate failed'));
    }
  };

  return (
    <Flex flexDirection="column" gap={8} width="100%">
      <Flex flexDirection="row" justifyContent="space-between" width={{ base: '100%', md: '100%' }}>
        <Flex>
          <img src={btcConnectedIcon} alt="btc" className={styles.tokenIcon} />
        </Flex>
        <Flex flexDirection="column">
          <Text fontSize={{ base: '20px', md: '32px' }} fontWeight={700} ml={2} textAlign="right">
            {`${truncateNumber(formatUnits(totalInvested, 8))} BTC`}
          </Text>
          <Text fontSize={{ base: '12px', md: '12px' }} ml={2} opacity={0.5} textAlign="right">
            {t('Raised (Quantity of BTC)', 'Raised (Quantity of BTC)')}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <Field label={t('Address', 'Address')} value={truncAddress(accountAddress)} />
        <Field label={t('Min Contribution', 'Min Contribution')} value={`${MIN_INVEST} BTC`} />
        <Field label={t('Max Contribution', 'Max Contribution')} value={`${MAX_INVEST} BTC`} />
        <Field label={t('My Balance', 'My Balance')} value={`${truncateNumber(formatUnits(nativeBalance, 8))} BTC`} />
        <Field label={t('My Contribution', 'My Contribution')} value={`${invested} BTC`} />
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <CommonInput
          type="number"
          value={inputAmount}
          onChange={(e) => setInputAmount(e.target.value)}
          placeholder={t('Enter amount', 'Enter amount')}
        />
        <CommonButton
          handleClick={hanldeInvest}
          name={t('Donate', 'Donate')}
          thick
          loading={isLoading}
          disabled={!process.env.REACT_APP_ALLOW_DONATE_BTC || !isStarted}
        />
      </Flex>
    </Flex>
  );
};

const EthConnectedSection = () => {
  const MIN_INVEST = 0.1;
  const MAX_INVEST = 5;
  const zuStore = useZuStore();
  const startCountdown = useCountdown(new Date('2024-01-24T12:00:00+08:00').getTime());
  const isStarted = startCountdown.difference === 0;
  const { walletName, walletType } = zuStore.walletInfo!;
  const { accountAddress, nativeBalance } = zuStore.userState!;

  const [inputAmount, setInputAmount] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const message = useMessage();
  const contracts = useContracts();
  console.log('contracts', contracts);

  const personQuery = useQuery({
    queryKey: ['person', accountAddress],
    queryFn: () => contracts.manager!.getPerson(accountAddress),
    enabled: !!contracts.manager && !!accountAddress && !!zuStore.userState?.token,
    refetchInterval: 1000 * 10
  });
  const invested = personQuery.isSuccess ? personQuery.data.investedEthAmount.toString() : 0;

  const totalInvestedQuery = useQuery({
    queryKey: ['totalInvestedQuery'],
    queryFn: () =>
      zuStore.providerState.provider.request({
        method: 'eth_getBalance',
        params: [contracts.ethDonateAddress, 'latest']
      }),
    enabled: !!zuStore.providerState.provider && !!contracts.ethDonateAddress,
    refetchInterval: 1000 * 10
  });
  const totalInvested = totalInvestedQuery.isSuccess ? totalInvestedQuery.data : 0;
  const { t } = useTranslation();

  const hanldeInvest = async () => {
    if (!inputAmount || !contracts.manager || Number.isNaN(inputAmount)) return;
    if (Number(inputAmount) < MIN_INVEST) {
      message.error(t('min contribution eth', 'Min contribution is {{MIN_INVEST}} ETH', { MIN_INVEST }));
      return;
    }
    if (Number(inputAmount) + Number(formatEther(invested)) > MAX_INVEST) {
      message.error(t('max contribution eth', 'Max contribution is {{MAX_INVEST}} ETH', { MAX_INVEST }));
      return;
    }
    if (!nativeBalance || BigNumber.from(parseEther(inputAmount)).sub(parseEther('0.005')).gt(nativeBalance)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));
      return;
    }
    setIsLoading(true);
    const amount = parseEther(inputAmount);
    console.log('invest amount', amount);

    try {
      const result = await contracts.manager!.invest(amount, { value: amount });
      setIsLoading(false);
      message.success(t('Invest success', 'Invest success'));
    } catch (error) {
      message.error(t('Invest failed', 'Invest failed'));
      setIsLoading(false);
    }
  };

  const handleIdoStart = async () => {
    if (!contracts.manager) return;
    const now = Math.floor(new Date().getTime() / 1000);
    console.log('now ', now);

    try {
      const result = await contracts.manager!.setTime(now - 60 * 60, now + 24 * 60 * 60);
      console.log('ido start', result);
    } catch (error) {
      console.log('ido start error', error);
    }
  };
  return (
    <Flex flexDirection="column" gap={8} width="100%">
      <Flex flexDirection="row" justifyContent="space-between" width={{ base: '100%', md: '100%' }}>
        {/* <Button onClick={handleIdoStart}>start</Button> */}
        <Flex>
          <img src={ethConnectedIcon} alt="btc" className={styles.tokenIcon} />
        </Flex>
        <Flex flexDirection="column">
          <Text fontSize={{ base: '20px', md: '32px' }} fontWeight={700} ml={2} textAlign="right">
            {`${truncateNumber(formatEther(totalInvested))} ETH`}
          </Text>
          <Text fontSize={{ base: '12px', md: '12px' }} ml={2} opacity={0.5} textAlign="right">
            {t('Raised (Quantity of ETH)', 'Raised (Quantity of ETH)')}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <Field label={t('Address', 'Address')} value={truncAddress(accountAddress)} />
        <Field label={t('Min Contribution', 'Min Contribution')} value={`${MIN_INVEST} ETH`} />
        <Field label={t('Max Contribution', 'Max Contribution')} value={`${MAX_INVEST} ETH`} />
        <Field label={t('My Balance', 'My Balance')} value={`${truncateNumber(formatEther(nativeBalance))} ETH`} />
        <Field label={t('My Contribution', 'My Contribution')} value={`${truncateNumber(formatEther(invested))} ETH`} />
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <CommonInput
          type="number"
          value={inputAmount}
          onChange={(e) => setInputAmount(e.target.value)}
          placeholder={t('Enter amount', 'Enter amount')}
        />
        <CommonButton
          handleClick={hanldeInvest}
          name={t('Donate', 'Donate')}
          thick
          loading={isLoading}
          disabled={!process.env.REACT_APP_ALLOW_DONATE_ETH || !isStarted}
        />
      </Flex>
    </Flex>
  );
};

const Field = ({ label, value }: { label: string; value: string }) => {
  return (
    <Flex flexDirection="row" justifyContent="space-between" width={{ base: '100%', md: '100%' }}>
      <Flex>
        <Text fontSize={{ base: '14px', md: '14px' }} opacity={0.5}>
          {label}
        </Text>
      </Flex>
      <Flex flexDirection="column">
        <Text fontSize={{ base: '20px', md: '14px' }} fontWeight={700} ml={2} textAlign="right">
          {value}
        </Text>
      </Flex>
    </Flex>
  );
};
