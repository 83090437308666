import { Flex, Text } from '@chakra-ui/react';
import { t } from 'i18next';
// import { CommonButton } from '../../components/UI/CommonButton';

export const Title = () => {
  return (
    <Flex
      flexDirection="column"
      width={{ base: '100%' }}
      alignItems={{ base: 'start' }}
      marginLeft={{ base: 'calc(100% + 20px - 370px)', md: 'calc((100% + 20px - 370px) / 2)', lg: '0px' }}
      gap={3}>
      <Text fontSize={{ base: '32px', md: '46px' }} fontWeight={700}>
        {t('Claim', 'Claim')}
      </Text>
      <Flex
        flexWrap="wrap"
        width="100%"
        rowGap={4}
        gap={{ base: 2, md: 4 }}
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'start', lg: 'center' }}>
        <LabeledText label={t('Contribution Token', 'Contribution Token')} value="BTC & ETH" />
        <LabeledText label={t('Event', 'Event')} value={t('Fair Launch', 'Fair Launch')} />
        <LabeledText label={t('Reward', 'Reward')} value="INFI" />
      </Flex>
    </Flex>
  );
};

const LabeledText = ({ label, value }: { label: string; value: string }) => {
  return (
    <Flex gap={1} justifyContent="end">
      <Flex rowGap={2} flexDirection={{ base: 'column', md: 'row' }} gap={2}>
        <Text fontSize={{ base: '15px', md: '16px' }} color="rgba(255,255,255,0.5)">
          {label}
        </Text>
        <Text fontSize={{ base: '16px', md: '16px' }} fontWeight={700} color="#F3BA2F">
          {value}
        </Text>
      </Flex>
    </Flex>
  );
};
