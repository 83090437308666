import { Divider, Flex, Text } from '@chakra-ui/react';
import styles from './MenuDropdown.module.scss';
import { truncAddress } from '../../utils/utils';
import { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { useZuStore } from '../../hooks/useZuStore';

type Props = {
  isOpen: boolean;
  onMenuClose: () => void;
};

export default ({ isOpen, onMenuClose }: Props) => {
  const zuStore = useZuStore();
  const navigate = useNavigate();

  const onContainerClick: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  const handleDisconnect = () => {
    zuStore.reset();
    onMenuClose();
  };

  const handleGotoWhiteListPage = () => {
    navigate('/whitelist');
    onMenuClose();
  };
  const handleGotoLaunchPage = () => {
    navigate('/fair-launch');
    onMenuClose();
  };

  if (!isOpen) return null;
  return (
    <OutsideClickHandler onOutsideClick={onMenuClose}>
      <Flex className={styles.container}>
        <Text className={styles.item} fontWeight={700}>
          {truncAddress(zuStore.userState?.accountAddress || '')}
        </Text>
        <Divider width="90%" height="1px" opacity={0.5} bg="white" />
        <Text className={styles.item} onClick={handleGotoLaunchPage} fontWeight={700}>
          Fair Launch
        </Text>
        <Divider width="90%" height="1px" opacity={0.5} bg="white" />
        <Text className={styles.item} onClick={handleGotoWhiteListPage} fontWeight={700}>
          WhiteList
        </Text>
        <Divider width="90%" height="1px" opacity={0.5} bg="white" />
        <Text className={styles.item} onClick={handleDisconnect} fontWeight={700}>
          Disconnect
        </Text>
      </Flex>
    </OutsideClickHandler>
  );
};
