import React from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import './index.css';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type CommonButtonProps = {
  handleClick: any;
  name: string;
  outline?: boolean;
  thick?: boolean;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};
export function CommonButton({
  rightIcon,
  leftIcon,
  handleClick,
  name,
  outline,
  className,
  disabled,
  loading,
  thick
}: CommonButtonProps) {
  const { t } = useTranslation();
  return (
    <Box
      className={classNames(
        'JoinButtonBox',
        thick && 'thickButton',
        outline && 'outlinedButton',
        disabled && 'disabledButton',
        loading && 'disabledButton',
        className
      )}
      onClick={disabled || loading ? () => {} : handleClick}>
      <Flex lineHeight="2.5rem" justifyContent="center" alignItems="center" gap={2} zIndex={2}>
        {leftIcon}
        {t(name)}
        {rightIcon}
        {loading && (
          <Box mt={1}>
            <Spinner />
          </Box>
        )}
      </Flex>
    </Box>
  );
}
