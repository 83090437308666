import React, { ChangeEvent } from 'react';
import { Box, Input } from '@chakra-ui/react';
import classNames from 'classnames';
import styles from './index.module.scss';

type CommonInputProps = {
  placeholder?: string;
  className?: string;
  type?: 'text' | 'number';
  disabled?: boolean;
  value: string | number;
  onChange: (e: any) => void;
  isError?: boolean;
};

export function CommonInput({
  placeholder = '',
  value,
  onChange,
  className,
  type = 'text',
  disabled,
  isError
}: CommonInputProps) {
  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      if (!Number.isNaN(Number(e.target.value))) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };
  return (
    <Box className={styles.container} >
      <Input
        variant="outline"
        placeholder={placeholder}
        value={value ? value : ''}
        onChange={onValueChange}
        size="lg"
        fontSize='12px'
        paddingLeft={5}
        zIndex={2}
        color='white'
        className={classNames(isError && styles.errorInput, className)}
        disabled={disabled}
      />
    </Box>
  );
}
