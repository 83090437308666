import React from 'react';
import styles from './index.module.scss';
import { Divider, Flex } from '@chakra-ui/react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import Title from './components/Title';
import Cards from './components/Cards';
import Referral from './components/Referral';
import { useQuery } from '@tanstack/react-query';
import { requestSelf } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { useZuStore } from '../../hooks/useZuStore';

export default () => {
  const zuStore = useZuStore();
  const token = zuStore.userState?.token;

  const selfQuery = useQuery({
    queryKey: ['self', token],
    queryFn: () => requestSelf(token!),
    staleTime: 1000 * 60,
    enabled: !!token
  });

  const inviteCount = selfQuery.isSuccess ? selfQuery.data.account.inviteeAmount : 0;
  const inviteCode = selfQuery.isSuccess ? selfQuery.data.account.inviteCode : '';
  const { t } = useTranslation();

  return (
    <Flex minHeight={{ base: 'calc(100vh - 60px)', md: 'calc(100vh - 80px)' }}>
      <MainContainer>
        <div className={styles.contentContainer}>
          <Flex justifyContent="space-between" alignItems="center">
            <Title isWhiteList={!!inviteCode} />
          </Flex>
          <Divider mt={4} mb={4} opacity={0.2} />

          {!!inviteCode && <Referral inviteCount={inviteCount} inviteCode={inviteCode} />}
          <Cards />
        </div>
      </MainContainer>
    </Flex>
  );
};
