import { create } from 'zustand';
import { getMetamaskProvider } from '../utils/wallet';
import { BtcConnectorInfo } from './wallets/types';

type WalletType = 'eth' | 'btc';
type EthereumWalletName = 'metamask' | 'okx' | 'tokenpocket';
type BtcWalletName = 'unisat' | 'okx';
type WalletName = EthereumWalletName | BtcWalletName;

type WalletInfo = {
  walletName: WalletName;
  walletType: WalletType;
};

type UserStateType = {
  accountId?: number;
  token?: string;
  accountAddress: string;
  nativeBalance: string;
};

type WalletModalStateType = {
  isModalOpen: boolean;
  onModalClose: () => void;
  onModalOpen: () => void;
};

type ProviderStateType = {
  provider: any;
  connect: () => Promise<string>;
  disconnect: () => void;
};

type StateType = {
  providerState: ProviderStateType;
  isConnected: boolean;

  walletInfo?: WalletInfo;
  userState?: UserStateType;
  walletModalInfo: WalletModalStateType;

  btcConnector?: BtcConnectorInfo;

  setBtcConnectorInfo: (btcConnector: BtcConnectorInfo) => void;
  setProvider: (provider: any) => void;
  setWalletInfo: (walletName: WalletInfo) => void;
  setIsConnected: (isConnected: boolean) => void;
  setUserState: (userState: UserStateType) => void;
  updateNativeBalance: (balance: string) => void;
  reset: () => void;
};

const defaultProvider = getMetamaskProvider();
const targetChain = `0x${Number(process.env.REACT_APP_CHAIN_ID! || 1).toString(16)}`;

const getConnectFnWithProvider = (provider: any) => async () => {
  try {
    const result = await provider.request({ method: 'eth_requestAccounts' });
    const network = await provider.request({ method: 'eth_chainId' });
    if (Number(network) !== Number(targetChain)) {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: targetChain }]
      });
    }
    return result[0];
  } catch (error) {
    console.log('error', error);
    return undefined;
  }
};

const initialState: StateType = {
  providerState: {
    provider: defaultProvider,
    connect: getConnectFnWithProvider(defaultProvider),
    disconnect: () => { }
  },
  walletInfo: {
    walletName: 'metamask',
    walletType: 'eth'
  },
  walletModalInfo: {
    isModalOpen: false,
    onModalClose: () => { },
    onModalOpen: () => { }
  },
  isConnected: false,
  setBtcConnectorInfo: () => { },
  setProvider: () => { },
  setWalletInfo: () => { },
  setIsConnected: () => { },
  setUserState: () => { },
  updateNativeBalance: () => { },
  reset: () => { }
};

export const useZuStore = create<StateType>((set, get) => ({
  providerState: initialState.providerState!,
  walletInfo: initialState.walletInfo,
  isConnected: initialState.isConnected!,
  userState: initialState.userState,
  btcConnector: initialState.btcConnector,
  walletModalInfo: {
    isModalOpen: false,
    onModalClose: () => {
      set({ walletModalInfo: { ...get().walletModalInfo, isModalOpen: false } });
    },
    onModalOpen: () => {
      set({ walletModalInfo: { ...get().walletModalInfo, isModalOpen: true } });
    }
  },
  setBtcConnectorInfo: (btcConnector: BtcConnectorInfo) => {
    btcConnector.provider.on('accountChanged', (addressInfo: any) => {
      set({ userState: undefined, isConnected: false, walletInfo: undefined });
    });
    return set({ btcConnector });
  },
  setProvider: (provider: any) => {
    provider.on('accountsChanged', (accounts: Array<string>) => {
      set({ userState: undefined, isConnected: false, walletInfo: undefined });
    });
    return set({
      providerState: {
        provider,
        connect: getConnectFnWithProvider(provider),
        disconnect: () => { }
      }
    });
  },
  setWalletInfo: (walletInfo: WalletInfo) => set({ walletInfo }),
  setIsConnected: (isConnected: boolean) => set({ isConnected }),
  setUserState: (userState: UserStateType) => set({ userState }),
  updateNativeBalance: (nativeBalance: string) =>
    set((state) => ({ userState: { ...state.userState!, nativeBalance } })),
  reset: () => set({ userState: undefined, isConnected: false, walletInfo: undefined })
}));
