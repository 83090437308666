import { Divider, Flex, Text } from '@chakra-ui/react';
import styles from './NetworkDropdown.module.scss';
import { useNavigate } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

type Props = {
  isOpen: boolean;
  onMenuClose: () => void;
  onBitCoinClick: () => void;
  onEthereumClick: () => void;
};

export default ({ isOpen, onMenuClose, onBitCoinClick, onEthereumClick }: Props) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);

  const handleBitCoinClick = () => {
    window.history.replaceState({}, '', '?type=btc');
    onBitCoinClick();
  };
  const handleEthereumClick = () => {
    window.history.replaceState({}, '', '?type=eth');
    onEthereumClick();
  };

  if (!isOpen) return null;
  return (
    <OutsideClickHandler onOutsideClick={onMenuClose}>
      <Flex className={styles.container}>
        <Text className={styles.item} onClick={handleBitCoinClick} fontWeight={700}>
          Bitcoin
        </Text>
        <Divider width="90%" height="1px" opacity={0.5} bg="white" />
        <Text className={styles.item} onClick={handleEthereumClick} fontWeight={700}>
          Ethereum
        </Text>
      </Flex>
    </OutsideClickHandler>
  );
};
