import { ethers } from 'ethers';
import { type ManagerInterface, NameServiceInterface__factory, ManagerInterface__factory } from '../typechain';
import { useMemo, useState } from 'react';
import { bytes32ToString } from '../utils/utils';

type ContractStatus = 'loading' | 'success' | 'error';
type NsRecords = {
  InvestEthReceive: string;
  Manager: string;
  Multicall3: string;
  SafetyBoxLogic: string;
};
interface Contracts {
  status: ContractStatus;
  entries?: any;
  manager?: ManagerInterface;
  ethDonateAddress?: string;
}

export const useContracts = (): Contracts => {
  const [ethDonateAddress, setEthDonateAddress] = useState<string>('');
  const [entries, setEntries] = useState<any>();
  const [manager, setManager] = useState<ManagerInterface>();
  if (window.ethereum == null) {
    return {
      status: 'error'
    };
  }

  const fetchInfo = useMemo(
    () => async () => {
      try {
        const nsAddress = process.env.REACT_APP_NS_ADDRESS!;
        const provider = new ethers.providers.Web3Provider(window.ethereum as any);
        const ns = NameServiceInterface__factory.connect(nsAddress, provider.getSigner());
        const rawEntries = await ns.listEntries();
        setEntries(rawEntries);
        const records: NsRecords = {
          InvestEthReceive: '',
          Manager: '',
          Multicall3: '',
          SafetyBoxLogic: ''
        };
        rawEntries.singleEntries.forEach((singleEntry) => {
          records[bytes32ToString(singleEntry.name) as keyof NsRecords] = singleEntry.record.addr;
        });
        const manager = ManagerInterface__factory.connect(records['Manager'], provider.getSigner());
        setManager(manager);
        setEthDonateAddress(records.InvestEthReceive);
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  fetchInfo();
  return {
    entries,
    manager,
    ethDonateAddress: ethDonateAddress,
    status: 'success'
  };
};
