import { Flex, Image, Text } from '@chakra-ui/react';
import valueIcon from '../../../assets/launch/value.png';
import inviteIcon from '../../../assets/launch/invite.png';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <Flex flexWrap="wrap" justifyContent="center" rowGap="20px">
      <Text fontSize={{ base: '16px', md: '16px' }} width="100%" textAlign="center" opacity={0.5} my="40px">
        {t('Whitelist Privileges', 'Whitelist Privileges')}
      </Text>
      <Flex width="100%" justifyContent="center" gap="20px" flexWrap="wrap">
        <Flex
          flexDirection="column"
          width={{ md: '40%', base: '100%' }}
          alignItems="center"
          padding="20px"
          border="1px solid #0C33BC"
          borderRadius="30px">
          <Text fontSize={{ base: '20px', md: '20px' }} fontWeight={700} width="100%" textAlign="center">
            {t('Welfare 1', 'Welfare 1')}
          </Text>
          <Image src={valueIcon} alt="logo" aspectRatio="auto" width={{ base: '200px', md: '200px' }} />
          <Text fontSize={{ base: '12px', md: '12px' }} width="100%" textAlign="center">
            {t('Get 1.5x token on investment value at any stage.', 'Get 1.5x token on investment value at any stage.')}
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          width={{ md: '40%', base: '100%' }}
          alignItems="center"
          padding="20px"
          border="1px solid #0C33BC"
          borderRadius="30px">
          <Text fontSize={{ base: '20px', md: '20px' }} fontWeight={700} width="100%" textAlign="center">
            {t('Welfare 2', 'Welfare 2')}
          </Text>
          <Image src={inviteIcon} alt="logo" aspectRatio="auto" width={{ base: '200px', md: '200px' }} />
          <Text fontSize={{ base: '12px', md: '12px' }} width="100%" textAlign="center">
            {t(
              'Earn airdrops and share in 5% of $ISFI supply with each successful referral.',
              'Earn airdrops and share in 5% of $ISFI supply with each successful referral.'
            )}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
