import Card1Icon from '../../../assets/image/vaults.png';
import Card2Icon from '../../../assets/image/launch.png';
import Card3Icon from '../../../assets/image/farm.png';
import Card4Icon from '../../../assets/image/bridge.png';
import Card5Icon from '../../../assets/image/aggregator.png';

// import Initialtive1Icon from '../../../assets/image/initiative1.png';
// import Initialtive2Icon from '../../../assets/image/initiative2.png';
// import Initialtive3Icon from '../../../assets/image/initiative3.png';

import Initialtive1Gif from '../../../assets/initatives/BRC_20.gif';
import Initialtive2Gif from '../../../assets/initatives/All-chain.gif';
import Initialtive3Gif from '../../../assets/initatives/AMM_swap.gif';

import newsIcon from '../../../assets/social/news.png';
import docIcon from '../../../assets/social/doc.png';
import telegramIcon from '../../../assets/social/telegram.png';
import mediumIcon from '../../../assets/social/medium.png';
// import twitterIcon from '../../../assets/social/twitter.png';

const getDefaultOption = (anim: any) => ({
  loop: true,
  autoplay: true,
  animationData: anim,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
});

export const socialItems = [
  {
    title: 'Telegram',
    icon: telegramIcon,
    link: 'https://t.me/InsFiGlobal'
  },
  // {
  //   title: 'Twitter',
  //   icon: twitterIcon,
  //   link: 'https://twitter.com/insfi_xyz'
  // },
  {
    title: 'Medium',
    icon: mediumIcon,
    link: 'https://medium.com/@insfi'
  },
  {
    title: 'Media Kit',
    icon: newsIcon,
    link: '/media-kit'
  },
  {
    title: 'Docs',
    icon: docIcon,
    link: 'https://docs.insfi.xyz'
  }
];

export const initiativeItems = [
  {
    title: 'BRC-20 Inscription Staking',
    icon: <img src={Initialtive1Gif} alt="Description of GIF" width="180px" />,
    content: 'initiative-1'
    // 'The INSFI protocol has established an NFT market aggregator that can serve inscription assets. It can link the entire network NFT market, conduct network-wide inquiry for a single NFT asset, and find the best quote to promote the liquidity of the NFT market.'
  },
  {
    title: 'All-Chain Inscription Staking',
    icon: <img src={Initialtive2Gif} alt="Description of GIF" width="180px" />,
    content: 'initiative-2'
    // 'The INSFI protocol has established an NFT market aggregator that can serve inscription assets. It can link the entire network NFT market, conduct network-wide inquiry for a single NFT asset, and find the best quote to promote the liquidity of the NFT market.'
  },
  {
    title: 'Inscription AMM Swap',
    icon: <img src={Initialtive3Gif} alt="Description of GIF" width="180px" />,
    content: 'initiative-3'
    // 'The INSFI protocol has established an NFT market aggregator that can serve inscription assets. It can link the entire network NFT market, conduct network-wide inquiry for a single NFT asset, and find the best quote to promote the liquidity of the NFT market.'
  }
];

export const cardItems = [
  {
    title: 'NFT aggregator',
    icon: Card5Icon,
    content: 'card-5'
    // 'The INSFI protocol has established an NFT market aggregator that can serve inscription assets. It can link the entire network NFT market, conduct network-wide inquiry for a single NFT asset, and find the best quote to promote the liquidity of the NFT market.'
  },
  {
    title: 'COMPOUND',
    icon: Card3Icon,
    content: 'card-3'
    // 'The INSFI protocol has established an NFT market aggregator that can serve inscription assets. It can link the entire network NFT market, conduct network-wide inquiry for a single NFT asset, and find the best quote to promote the liquidity of the NFT market.'
  },
  {
    title: 'FARM',
    icon: Card2Icon,
    content: 'card-2'
    // 'The INSFI protocol has established an NFT market aggregator that can serve inscription assets. It can link the entire network NFT market, conduct network-wide inquiry for a single NFT asset, and find the best quote to promote the liquidity of the NFT market.'
  },
  {
    title: 'DEX',
    icon: Card1Icon,
    content: 'card-1'
    // 'The INSFI protocol has established an NFT market aggregator that can serve inscription assets. It can link the entire network NFT market, conduct network-wide inquiry for a single NFT asset, and find the best quote to promote the liquidity of the NFT market.'
  },
  {
    title: 'BRIDGE',
    icon: Card4Icon,
    content: 'card-4'
    // 'The INSFI protocol has established an NFT market aggregator that can serve inscription assets. It can link the entire network NFT market, conduct network-wide inquiry for a single NFT asset, and find the best quote to promote the liquidity of the NFT market.'
  }
];

import LiquidityIcon from '../../../assets/image/liquidity.png';
import SecurityIcon from '../../../assets/image/security.png';
import ScalabilityIcon from '../../../assets/image/scalability.png';

export const benifitItems = [
  {
    title: 'First-of-its-kind',
    icon: LiquidityIcon,
    content: 'benefit-1'
    // 'INSFI sets a precedent in the blockchain sector as the pioneering protocol for earning from Inscription assets, reshaping the way these assets are utilized and traded.'
  },
  {
    title: 'Enhanced Liquidity',
    icon: SecurityIcon,
    content: 'benefit-2'
    // 'Utilizing smart contracts, the protocol enhances liquidity supply by identifying profitable agreements, leading to reduced transaction costs and a more streamlined trading environment.'
  },
  {
    title: 'Fix-Income',
    icon: ScalabilityIcon,
    content: 'benefit-3'
    // 'By employing a fixed income strategy with investments managed by a NASDAQ-listed company, INSFI provides stable and reliable returns to its investors.'
  }
];
