import React, { useEffect } from 'react';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './index.module.scss';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IoClose } from 'react-icons/io5';

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
  title: string;
  hideCloseBtn?: boolean;
  className?: string;
};

export default (props: Props) => {
  const { isOpen, children, onClose, className, title, hideCloseBtn } = props;
  const handleOutsideClick = (e: any) => {
    e.preventDefault();
    if (!hideCloseBtn) {
      onClose();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // close modal when press ESC
      if (event.keyCode === 27 && !hideCloseBtn) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  if (!isOpen) {
    return null;
  }
  return (
    <>
      <div className={styles.overlay} />
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div className={classNames(styles.container, className)}>
          <Flex width="100%" alignItems="center" justifyContent="space-between" mb="20px">
            <Text fontSize="24px" fontWeight={600}>
              {title}
            </Text>
            {!hideCloseBtn && (
              <Box width="32px" onClick={onClose}>
                <IoClose />
              </Box>
            )}
          </Flex>
          {children}
        </div>
      </OutsideClickHandler>
    </>
  );
};
