import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Divider, Flex } from '@chakra-ui/react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import Title from './components/Title';
import Countdown from './components/Countdown';
import TokenSection from './components/TokenSection';
import { useIsMobile } from '../../utils/utils';
import { Swither } from '../../components/UI/Swither';
import BtcWallets from '../../components/WalletsModal';
import LoginModal from '../../components/LoginModal';
import { requestHasRegistered } from '../../utils/api';
import { useZuStore } from '../../hooks/useZuStore';

export default () => {
  const zuStore = useZuStore();
  const [isBtcOpen, setisBtcOpen] = useState(false);
  const onBtcClose = () => setisBtcOpen(false);
  const onBtcOpen = () => setisBtcOpen(true);

  const [isLoginOpen, setisLoginOpen] = useState(false);
  const onLoginClose = () => setisLoginOpen(false);
  const onLoginOpen = () => setisLoginOpen(true);

  const [walletAddress, setWalletAddress] = useState(zuStore.userState?.accountAddress || '');
  const [hasRegistered, setHasRegistered] = useState(false);

  const handleWalletConnected = async (walletAddress: string) => {
    setWalletAddress(walletAddress);
    const isRegistered = await requestHasRegistered(walletAddress);
    setHasRegistered(isRegistered.result);
    if (!zuStore.userState?.token) {
      onLoginOpen();
    }
  };

  const isMobile = useIsMobile();

  const provider = zuStore.providerState.provider;
  const nativeBalance = zuStore.userState?.nativeBalance;
  const targetWalletAddress = zuStore.userState?.accountAddress;
  useEffect(() => {
    const fetchEthBalance = async () => {
      // ethereum balances
      if (zuStore.walletInfo?.walletType === 'eth' && provider && targetWalletAddress) {
        const balance = await provider.request({
          method: 'eth_getBalance',
          params: [targetWalletAddress, 'latest']
        });
        zuStore.updateNativeBalance(balance);
      }

      // btc balance
      if (
        zuStore.walletInfo?.walletType === 'btc' &&
        zuStore.btcConnector
      ) {
        const balance = await zuStore.btcConnector?.getBalance();
        console.log('balance', balance);
        
        zuStore.updateNativeBalance(balance);
      }

    };
    const interval = setInterval(async () => {
      await fetchEthBalance();
    }, 5 * 1000);
    return () => clearInterval(interval);
  }, [provider, targetWalletAddress]);

  const Desktop = (
    <Flex minHeight="calc(100vh - 80px)">
      <MainContainer>
        <div className={styles.contentContainer}>
          <Flex justifyContent="space-between" alignItems="center">
            <Title />
            {/* <Volumn /> */}
          </Flex>
          <Divider mt={4} mb={4} opacity={0.2} />
          <Countdown />

          <Flex flexDirection="column" px={{ base: 0, md: '20px' }} mt={8}>
            <Swither
              item1={'Bitcoin'}
              item2={'Ethereum'}
              component1={<TokenSection type="btc" onConnectWallet={onBtcOpen} />}
              component2={<TokenSection type="eth" onConnectWallet={onBtcOpen} />}
            />
          </Flex>
        </div>
      </MainContainer>
    </Flex>
  );

  const Mobile = (
    <Flex minHeight="calc(100vh - 60px)">
      <MainContainer>
        <div className={styles.contentContainer}>
          <Flex flexDirection="column" justifyContent="space-between" alignItems="center">
            {/* <Flex background="#0C1B5D" width="100%" px="22px" py="32px" borderRadius="12px" justifyContent="center">
              <Volumn />
            </Flex> */}
            <Title />
          </Flex>
          <Countdown />

          <Flex flexDirection="column" px={{ base: 0, md: '20px' }} mt={8}>
            <Swither
              item1={'Bitcoin'}
              item2={'Ethereum'}
              component1={<TokenSection type="btc" onConnectWallet={onBtcOpen} />}
              component2={<TokenSection type="eth" onConnectWallet={onBtcOpen} />}
            />
          </Flex>
        </div>
      </MainContainer>
    </Flex>
  );

  const component = isMobile ? Mobile : Desktop;
  return (
    <>
      {component}
      <BtcWallets isOpen={isBtcOpen} onClose={onBtcClose} onConnected={handleWalletConnected} />
      <LoginModal
        walletAddress={walletAddress}
        hasRegistered={hasRegistered}
        isOpen={isLoginOpen}
        onClose={onLoginClose}
      />
    </>
  );
};
