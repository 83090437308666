import { getUnisat } from './../../utils/wallet';
import { BtcConnectorInfo } from './types';

type UnisatBalance = {
  confirmed: number;
  total: number;
  unconfirmed: number;
};

export const getUnisatInfo = async (): Promise<BtcConnectorInfo | null> => {
  const unisat = getUnisat();
  if (!unisat) return null;

  const provider = unisat;
  const connect = async () => {
    const result = await unisat.requestAccounts();
    const walletAddress = result[0];
    return walletAddress;
  };
  const sendBitcoin = async (to: string, amount: number) => {
    let txid = await unisat.sendBitcoin(to, amount);
    return txid;
  };
  const signMessage = async (message: string) => {
    return await unisat.signMessage(message);
  };
  const getBalance = async () => {
    const result: UnisatBalance = await unisat.getBalance();
    return `0x${result.total.toString(16)}`;
  };
  return {
    provider,
    connect,
    sendBitcoin,
    signMessage,
    getBalance
  };
};

export const getOkxInfo = async (): Promise<BtcConnectorInfo | null> => {
  const okxwallet = window.okxwallet?.bitcoin;
  if (!okxwallet) return null;

  const provider = okxwallet;
  const connect = async () => {
    const result = await okxwallet.connect();
    // let result = await okxwallet.requestAccounts();
    return result.address;
  };
  const sendBitcoin = async (to: string, amount: number) => {
    let txid = await okxwallet.sendBitcoin(to, amount);
    return txid;
  };
  const signMessage = async (message: string) => {
    return await okxwallet.signMessage(message, 'ecdsa');
  };
  const getBalance = async () => {
    const result: UnisatBalance = await okxwallet.getBalance();
    return `0x${result.total.toString(16)}`;
  };
  return {
    provider,
    connect,
    sendBitcoin,
    signMessage,
    getBalance
  };
};
