import { Flex, Text, Image } from '@chakra-ui/react';
import ordiIcon from './ordi.png';
import ratsIcon from './rats.png';
import satsIcon from './sats.png';
import { t } from 'i18next';
import { CommonButton } from '../../components/UI/CommonButton';
import styles from './index.module.scss';

export const Staked = () => {
  return (
    <Flex flexDirection="column" gap={4}>
      <Flex maxWidth={{ base: '100%', md: '800px' }} gap={4} flexWrap="wrap">
        <Flex flexDirection="column" width="47%" gap={4}>
          <Text fontSize="20px" fontWeight={700}>
            {t('Staked', 'Staked')}
          </Text>
          <Flex width="100%" justifyContent="space-between" flexDirection="column" gap={2}>
            <LabeledText label="ORDI" value="-" icon={ordiIcon} />
            <LabeledText label="RATS" value="-" icon={ratsIcon} />
            <LabeledText label="SATS" value="-" icon={satsIcon} />
          </Flex>
        </Flex>
        <Flex flexDirection="column" width="47%" gap={4}>
          <Text fontSize="20px" fontWeight={700}>
            {t('Total Earned', 'Total Earned')}
          </Text>
          <Flex width="100%" justifyContent="space-between" flexDirection="column" gap={2}>
            <LabeledText label="INFI" value="-" />
          </Flex>
        </Flex>
        <Flex flexDirection="column" width="47%" gap={4}>
          <Text fontSize="20px" fontWeight={700}>
            {t('Claimable', 'Claimable')}
          </Text>
          <Flex width="100%" justifyContent="space-between" flexDirection="column" gap={2}>
            <LabeledText label="INFI" value="-" />
          </Flex>
        </Flex>
      </Flex>
      <CommonButton handleClick={undefined} name={'Claim'} className={styles.claimBtn} />
    </Flex>
  );
};

const LabeledText = ({ icon, label, value }: { icon?: string; label: string; value: string }) => {
  return (
    <Flex flexDirection="row" gap={2} justifyContent="start">
      {!!icon && <Image src={icon} width="30px" aspectRatio="auto" />}
      <Text fontSize={{ base: '20px', md: '20px' }} color="#123DDE" fontWeight={700}>
        {value} {label}
      </Text>
    </Flex>
  );
};
