import { Flex, Image, Text } from '@chakra-ui/react';
import copyIcon from '../../../assets/image/copy.png';
import { CommonButton } from '../../../components/UI/CommonButton';
import { useMessage } from '../../../hooks/useMessage';

type Props = {
  inviteCount: number;
  inviteCode: string;
};

export default ({ inviteCode, inviteCount }: Props) => {
  return (
    <Flex flexWrap="wrap" justifyContent={{ base: 'center', md: 'space-between' }} rowGap="20px">
      <Counts count={inviteCount} />
      <RefLink inviteCode={inviteCode} />
      <InviteCode inviteCode={inviteCode} />
    </Flex>
  );
};

const Counts = (props: { count: number }) => (
  <Flex
    justifyContent="space-around"
    width={{ base: '130px', md: '140px' }}
    height="72px"
    alignItems="center"
    border=" 1px solid #032452"
    borderRadius="12px">
    <Flex flexDirection="column">
      <Text fontSize="12px" fontWeight={700} width="100%" textAlign="center">
        {props.count}
      </Text>
      <Text fontSize="12px" width="100%" textAlign="center">
        Referrals
      </Text>
    </Flex>
    {/* <Divider orientation="vertical" height="30px" />
    <Flex flexDirection="column">
      <Text fontSize="12px" fontWeight={700} width="100%" textAlign="center">
        20
      </Text>
      <Text fontSize="12px" width="100%" textAlign="center">
        Referrals
      </Text>
    </Flex> */}
  </Flex>
);
const RefLink = (props: { inviteCode: string }) => {
  const message = useMessage();
  const link = `${window.origin}/fair-launch?inviteCode=${props.inviteCode}`;
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(link);
      message.success('Copied');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  return (
    <Flex
      justifyContent="space-around"
      width="330px"
      height="72px"
      alignItems="center"
      border=" 1px solid #032452"
      borderRadius="12px">
      <Flex flexDirection="column">
        <Text fontSize="12px" width="100%">
          Reerral Link:
        </Text>
        <Text
          fontSize="12px"
          width="100%"
          textAlign="center"
          maxWidth="185px"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap">
          {link}
        </Text>
      </Flex>
      <CommonButton handleClick={handleCopy} name={'Copy Link'} />
    </Flex>
  );
};
const InviteCode = (props: { inviteCode: string }) => {
  const message = useMessage();
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(props.inviteCode);
      message.success('Copied');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  return (
    <Flex
      justifyContent="space-between"
      px="20px"
      width={{ base: '330px', md: '180px' }}
      height="72px"
      alignItems="center"
      border=" 1px solid #032452"
      borderRadius="12px">
      <Flex flexDirection="column">
        <Text fontSize="12px" width="100%">
          Invitation Code:
        </Text>
        <Text
          fontSize="12px"
          width="100%"
          textAlign="center"
          maxWidth="185px"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap">
          {props.inviteCode}
        </Text>
      </Flex>
      <Image src={copyIcon} width="20px" height="24px" onClick={handleCopy} cursor="pointer" />
    </Flex>
  );
};
