import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

type CardProps = {
  title: string;
  icon: React.ReactNode;
  content: string;
  link?: string;
  classname?: string;
};
export function InitiativeCard({ title, icon, content, link, classname }: CardProps) {
  const { t } = useTranslation();
  return (
    <Box className={classNames(styles.container, classname)}>
      <Flex lineHeight="2.5rem" justifyContent="space-between" alignItems="center" gap={2} zIndex={2}>
        {icon}
      </Flex>
      <Text className={styles.title}>{t(title)}</Text>
      <Text className={styles.learnMore}>{t('Coming Soon', 'Coming Soon')}</Text>
    </Box>
  );
}
