import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import styles from './index.module.scss';
import classnames from 'classnames';

type CardProps = {
  item1: string;
  item2: string;
  component1: React.ReactNode;
  component2: React.ReactNode;
  classname?: string;
};
export function Swither({ item1, item2, component1, component2, classname }: CardProps) {
  const query = new URLSearchParams(window.location.search);
  const type: 'btc' | 'eth' = query.get('type') === 'btc' ? 'btc' : 'eth';
  const isBtc = type === 'btc';

  const [active, setActive] = React.useState(isBtc ? 1 : 2);
  const toggle = () => {
    if (active === 1) {
      setActive(2);
      window.history.replaceState({}, '', '?type=eth');
    } else {
      setActive(1);
      window.history.replaceState({}, '', '?type=btc');
    }
  };
  return (
    <Flex flexDirection="column" gap={8} alignItems="center">
      <Flex className={classNames(styles.container, classname)} onClick={toggle}>
        <Flex>
          <Text className={classnames(styles.item, active === 1 && styles.selected)}>{item1}</Text>
        </Flex>
        <Flex>
          <Text className={classnames(styles.item, active === 2 && styles.selected)}>{item2}</Text>
        </Flex>
      </Flex>
      <Flex
        width="100%"
        background="rgba(0, 0, 0, 0.10)"
        borderRadius="24px"
        padding="20px 30px"
        display={active === 1 ? 'flex' : 'none'}
        backdropFilter="blur(30px)">
        {component1}
      </Flex>
      <Flex
        width="100%"
        background="rgba(0, 0, 0, 0.10)"
        borderRadius="24px"
        padding="20px 30px"
        display={active === 2 ? 'flex' : 'none'}
        backdropFilter="blur(30px)">
        {component2}
      </Flex>
    </Flex>
  );
}
