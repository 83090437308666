import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, Image, Text, useDisclosure } from '@chakra-ui/react';
import styles from './index.module.scss';
import MainContainer from '../UI/MainContainer/MainContainer';
import { truncAddress, useIsMobile } from '../../utils/utils';
// import LogoIcon from '../../assets/image/logo.png';
import Icon from '../../assets/image/icon.png';
import { useNavigate } from 'react-router-dom';
import { useMessage as useMessage } from '../../hooks/useMessage';
import { ReactComponent as BurgerIcon } from './burger.svg';
import { ReactComponent as CloseIcon } from './close.svg';
import MenuDropdown from './MenuDropdown';
import BtcWallets from '../WalletsModal';
import LoginModal from '../LoginModal';
import { useState } from 'react';
import { requestHasRegistered } from '../../utils/api';
import NetworkDropdown from './NetworkDropdown';
import { useTranslation } from 'react-i18next';
import LangButton from './LangButton/LangButton';
import { useZuStore } from '../../hooks/useZuStore';

export function Header() {
  const zuStore = useZuStore();
  const userInfoState = zuStore.userState;
  const isWalletModalOpen = zuStore.walletModalInfo.isModalOpen;
  const onWalletModalClose = zuStore.walletModalInfo.onModalClose;
  const onWalletModalOpen = zuStore.walletModalInfo.onModalOpen;

  const [isLoginOpen, setisLoginOpen] = useState(false);
  const onLoginClose = () => setisLoginOpen(false);
  const onLoginOpen = () => setisLoginOpen(true);

  const [walletAddress, setWalletAddress] = useState('');
  const [hasRegistered, setHasRegistered] = useState(false);

  const handleWalletConnected = async (walletAddress: string) => {
    setWalletAddress(walletAddress);
    const isRegistered = await requestHasRegistered(walletAddress);
    setHasRegistered(isRegistered.result);
    if (!userInfoState?.token) {
      onLoginOpen();
    }
  };

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isMenuOpen, onClose: onMenuClose, onOpen: onMenuOpen } = useDisclosure();
  const { isOpen: isNetworkOpen, onClose: onNetworkClose, onOpen: onNetworkOpen } = useDisclosure();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const message = useMessage();

  const { t } = useTranslation();

  const menuItems = [
    {
      name: 'Home',
      link: '/'
    },
    // {
    //   name: 'Fair Launch',
    //   link: '/fair-launch'
    // },
    // {
    //   name: 'Whitelist',
    //   link: '/whitelist'
    // },
    {
      name: 'Claim',
      link: '/claim'
    },
    {
      name: 'Stake',
      link: '/stake'
    },
    {
      name: 'Earn'
    },
    {
      name: 'Media Kit',
      link: '/media-kit'
    },
    {
      name: 'Docs',
      link: 'https://docs.insfi.xyz'
    }
  ];

  return (
    <>
      <Box className={styles.container}>
        <MainContainer className={styles.contentContainer}>
          <Flex width="100%" color="white" alignItems="center" justifyContent={'space-between'}>
            <Flex alignItems="center" cursor="pointer" gap="30px">
              <Image
                src={Icon}
                alt="logo"
                aspectRatio="auto"
                height={{ base: '32px', md: '40px' }}
                onClick={() => navigate('/')}
              />
              {!isMobile && (
                <Flex gap={{ base: '10px', lg: '30px' }} justifyContent="space-between">
                  {menuItems.map((item) => (
                    <Text
                      key={item.name}
                      fontWeight={700}
                      fontSize={{ base: '14px', lg: '18px' }}
                      cursor="pointer"
                      fontFamily="DIN"
                      onClick={
                        item.link
                          ? () => {
                              if (item.link.startsWith('http')) {
                                window.open(item.link, '_blank');
                              } else {
                                navigate(item.link);
                              }
                            }
                          : () => message.info(t('Coming soon', 'Coming soon'))
                      }>
                      {t(item.name)}
                    </Text>
                  ))}
                </Flex>
              )}
            </Flex>

            <Flex justifyContent="end" gap={'1rem'}>
              {!userInfoState?.accountAddress && (
                <Flex justifySelf="right">
                  <Flex
                    className={styles.profile}
                    alignItems="center"
                    justifyContent="center"
                    onClick={onNetworkOpen}
                    cursor="pointer">
                    {t('Connect', 'Connect')}
                  </Flex>
                </Flex>
              )}
              {userInfoState?.accountAddress && (
                <Flex justifySelf="right">
                  <Flex
                    className={styles.profile}
                    alignItems="center"
                    justifyContent="center"
                    onClick={onMenuOpen}
                    cursor="pointer">
                    {truncAddress(userInfoState.accountAddress)}
                  </Flex>
                </Flex>
              )}
              {!isMobile && <LangButton />}
              {isMobile && (
                <Flex justifySelf="right" pr={2} onClick={onOpen} alignItems="center" ml={4}>
                  <BurgerIcon />
                </Flex>
              )}
            </Flex>
          </Flex>
          <MenuDropdown isOpen={isMenuOpen} onMenuClose={onMenuClose} />
          <NetworkDropdown
            isOpen={isNetworkOpen}
            onMenuClose={onNetworkClose}
            onBitCoinClick={onWalletModalOpen}
            onEthereumClick={onWalletModalOpen}
          />
        </MainContainer>
      </Box>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <Flex
            bg="conic-gradient(from 252deg at 50% 50%, #0F2271 0deg, #0B174C 23.399999141693115deg, #011772 98.06128263473511deg, #030D37 233.99999141693115deg, #070E2D 360deg)"
            width="100%"
            height="100%"
            flexDirection="column"
            padding="20px">
            <Flex width="100%" flexDirection="row-reverse">
              <CloseIcon onClick={onClose} />
            </Flex>
            <Flex width="100%" height="100%" flexDirection="column" color="white" mt="40px" gap="20px">
              {menuItems.map((item) => (
                <Text
                  key={item.name}
                  fontWeight={700}
                  cursor="pointer"
                  textAlign="right"
                  onClick={() => {
                    const gotoLink = item.link
                      ? () => {
                          if (item.link.startsWith('http')) {
                            window.open(item.link, '_blank');
                          } else {
                            navigate(item.link);
                          }
                        }
                      : () => message.info('Coming soon');
                    gotoLink();
                    onClose();
                  }}>
                  {t(item.name)}
                </Text>
              ))}
            </Flex>
            <LangButton />
          </Flex>
        </DrawerContent>
      </Drawer>

      <BtcWallets isOpen={isWalletModalOpen} onClose={onWalletModalClose} onConnected={handleWalletConnected} />
      <LoginModal
        walletAddress={walletAddress}
        hasRegistered={hasRegistered}
        isOpen={isLoginOpen}
        onClose={onLoginClose}
      />
    </>
  );
}
