import { Divider, Flex, Text } from '@chakra-ui/react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import { CommonButton } from '../../components/UI/CommonButton';
// import downloadIcon from '../../assets/image/dowload.png';
import { MdOutlineFileDownload } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const fileNames = [
  'IF Token symbol.SVG.png',
  'IF Token symbol.SVG.svg',
  'Logo_Light_vertical_full.SVG.svg',
  'Logo_Light_vertical_full.SVG.png',
  'Logo_Light_horizontal_full.SVG.svg',
  'Logo_Light_horizontal_full.SVG.png',
  'Logo_Dark_vertical_full.SVG.svg',
  'Logo_Dark_vertical_full.SVG.png',
  'Logo_Dark_horizontal_full.SVG.svg',
  'Logo_Dark_horizontal_full.SVG.png',
  'INSFI Symbol.SVG.svg',
  'INSFI Symbol.SVG.png'
];

export default function MediaKit() {
  const { t } = useTranslation();
  return (
    <Flex minHeight={{ base: 'calc(100vh - 60px)', md: 'calc(100vh - 80px)' }}>
      <MainContainer>
        <Flex justifyContent="center" gap="200px" alignItems="start" mb={4} mt="100px" flexWrap="wrap">
          <Flex flexDirection="column" gap="20px">
            <Text fontSize="24px" fontWeight={700}>
              {t('MEDIA INQUIRIES', 'MEDIA INQUIRIES')}
            </Text>
            <a
              href="https://drive.google.com/drive/folders/1YgEhW95zzm3K7NFuG_-lGMoCXWwekBUH?usp=sharing" // Adjust the path and filename as necessary
              download
              className="download-button">
              <CommonButton handleClick={undefined} name={'Download Complete Kit'} />
            </a>
          </Flex>
          <Flex flexDirection="column" gap="40px" width={{ base: '90%', md: '40%' }}>
            {fileNames.map((fileName) => (
              <Flex flexDirection="column" gap="40px" width="100%">
                <Divider opacity={0.5} />
                <Text fontSize="20px">{fileName}</Text>
                <img src={`/media/${fileName}`} alt="placeholder" width="160px" height="160px" />

                <a
                  href={`/media/${fileName}`} // Adjust the path and filename as necessary
                  download
                  className="download-button">
                  <CommonButton
                    handleClick={undefined}
                    name={'Download'}
                    outline
                    rightIcon={<MdOutlineFileDownload />}
                  />
                </a>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </MainContainer>
    </Flex>
  );
}
