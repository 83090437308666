import { Flex, Text, Image } from '@chakra-ui/react';
import ordiIcon from './ordi.png';
import ratsIcon from './rats.png';
import satsIcon from './sats.png';
import { t } from 'i18next';
import { CommonButton } from '../../components/UI/CommonButton';
import styles from './index.module.scss';

export const Pool = () => {
  return (
    <Flex flexDirection="column" gap={4} padding="15px" width={{ base: '100%', md: '100%' }}>
      <Text fontSize={{ base: '30px', md: '30px' }} fontWeight={700}>
        {t('Pool', 'Pool')}
      </Text>
      <Flex flexDirection="column" gap={4} width="100%">
        <PoolItem
          label="ORDI"
          icon={ordiIcon}
          staked={'-'}
          apy={'-'}
          claimable={'-'}
          onWithdraw={undefined}
          onClaim={undefined}
        />
        <PoolItem
          label="RATS"
          icon={ratsIcon}
          staked={'-'}
          apy={'-'}
          claimable={'-'}
          onWithdraw={undefined}
          onClaim={undefined}
        />
        <PoolItem
          label="SATS"
          icon={satsIcon}
          staked={'-'}
          apy={'-'}
          claimable={'-'}
          onWithdraw={undefined}
          onClaim={undefined}
        />
      </Flex>
    </Flex>
  );
};

const PoolItem = ({
  icon,
  apy,
  staked,
  claimable,
  label
}: {
  icon: string;
  apy: string;
  label: string;
  staked: string;
  claimable: string;
  onWithdraw?: () => void;
  onClaim?: () => void;
}) => {
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      gap={1}
      justifyContent="start"
      padding={4}
      className={styles.card}
      width="100%">
      <Flex alignItems="center" mr={8}>
        <Image src={icon} height="30px" aspectRatio="initial" />
        <Text fontSize={{ base: '20px', md: '20px' }} fontWeight={700} ml={{ base: '10px', md: '10px' }}>
          {label}
        </Text>
      </Flex>
      <Flex
        flexDirection={{ base: 'row', md: 'row' }}
        flexWrap="wrap"
        justifyContent="space-between"
        mb={{ base: 4, md: 0 }}
        mt={{ base: 4, md: 0 }}
        gap={{ base: 2, md: 12 }}>
        <LabeledText label="APY" value={`${apy}%`} />
        <LabeledText label={t('Staked', 'Staked')} value={staked} />
        <LabeledText label={t('Release', 'Release')} value={`1 ${t('Month', 'Month')}`} />
        <LabeledText label={t('Claimable', 'Claimable')} value={claimable} />
      </Flex>
      <Flex
        ml={{ base: 0, md: 'auto' }}
        gap={4}
        alignItems="center"
        width={{ base: '100%', md: 'auto' }}
        justifyContent="space-between">
        <CommonButton
          handleClick={undefined}
          name={t('Claim', 'Claim')}
          className={styles.poolStakeBtn}
          outline
          disabled
        />
        <CommonButton
          handleClick={undefined}
          name={t('Withdraw', 'Withdraw')}
          className={styles.poolStakeBtn}
          outline
          disabled
        />
      </Flex>
    </Flex>
  );
};

const LabeledText = ({ label, value }: { label: string; value: string }) => {
  return (
    <Flex flexDirection={{ base: 'column', md: 'column' }} width={{ base: '45%', md: 'auto' }}>
      <Text fontSize={14} fontWeight={400}>
        {label}
      </Text>
      <Text fontSize={14} fontWeight={700}>
        {value}
      </Text>
    </Flex>
  );
};
