import { Flex, Text, Image } from '@chakra-ui/react';
import ordiIcon from './ordi.png';
import ratsIcon from './rats.png';
import satsIcon from './sats.png';
import { t } from 'i18next';
import { CommonButton } from '../../components/UI/CommonButton';
import styles from './index.module.scss';

export const Balance = () => {
  return (
    <Flex flexDirection="column" gap={4} width={{ base: '100%', md: 290 }}>
      <Text fontSize={{ base: '30px', md: '30px' }} fontWeight={500}>
        {t('BRC20 Balance', 'BRC20 Balance')}
      </Text>
      <Flex flexDirection="column" gap={4} className={styles.card} padding="15px">
        <LabeledText label="ORDI" value="-" icon={ordiIcon} />
        <LabeledText label="RATS" value="-" icon={ratsIcon} />
        <LabeledText label="SATS" value="-" icon={satsIcon} />
      </Flex>
    </Flex>
  );
};

const LabeledText = ({ icon, label, value }: { icon?: string; label: string; value: string }) => {
  return (
    <Flex flexDirection="row" gap={1} justifyContent="start" alignItems="center">
      {!!icon && <Image src={icon} height="45px" aspectRatio="initial" />}
      <Flex flexDirection="column">
        <Text fontSize={{ base: '20px', md: '20px' }} fontWeight={700} ml={{ base: '10px', md: '10px' }}>
          {label}
        </Text>
        <Text fontSize={{ base: '20px', md: '20px' }} fontWeight={700} ml={{ base: '10px', md: '10px' }}>
          {value}
        </Text>
      </Flex>
      <CommonButton handleClick={undefined} name={t('Stake', 'Stake')} className={styles.stakeBtn} outline />
    </Flex>
  );
};
