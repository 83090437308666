import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

type CardProps = {
  title: string;
  icon: string;
  content: string;
  classname?: string;
};
export function BenifitCard({ title, icon, content, classname }: CardProps) {
  const { t } = useTranslation();
  return (
    <Box className={classNames(styles.container, classname)}>
      {/* <Image src={icon} width='100px' height='100px' /> */}

      <Flex
        lineHeight="2.5rem"
        justifyContent="space-between"
        flexDirection="column"
        zIndex={2}
        width={{ base: '100%', md: '400px' }}>
        <Text className={styles.title}>{t(title)}</Text>
        <Text className={styles.content}>{t(content)}</Text>
      </Flex>
    </Box>
  );
}
