import { useState, useEffect } from 'react';

export const useCountdown = (targetDate: string | number) => {
  const countDownDate = new Date(targetDate).getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = countDownDate - now;

    let timeLeft: {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
      difference: number;
    } = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      difference: 0
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
        difference
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return timeLeft;
};
