import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';

type CardProps = {
  title: string;
  icon: string;
  link?: string;
  classname?: string;
};
export function SocialCard({ title, icon, link, classname }: CardProps) {
  const navigate = useNavigate();
  return (
    <Box className={classNames(styles.container, classname)}>
      <a href={link} target="_blank">
        <Text className={styles.title}>{title}</Text>
        <Image src={icon} width="60px" height="60px" cursor="pointer" />
      </a>
      {/* <Flex lineHeight="2.5rem" justifyContent="space-between" alignItems='center' gap={2} zIndex={2}>
      </Flex> */}
    </Box>
  );
}
