import React from 'react';
// import styles from './index.module.scss';
import { Flex, Text } from '@chakra-ui/react';
// import { ReactComponent as ColonIcon } from '../../../assets/launch/colon.svg';
import { useCountdown } from '../../../hooks/useCountdown';
import { useTranslation } from 'react-i18next';

export default () => {
  const startCountdown = useCountdown(new Date('2024-01-24T12:00:00+08:00').getTime());
  const endCountdown = useCountdown(new Date('2024-01-25T12:00:00+08:00').getTime());
  const alreadyStarted = startCountdown.difference === 0;
  const countdown = alreadyStarted ? endCountdown : startCountdown;
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" alignItems="center" width="100%">
      <Text fontSize={{ base: '14px', md: '14px' }} opacity={0.5} mt={4} mb={4}>
        {alreadyStarted ? t('Ends in', 'Ends in') : t('Starts in', 'Starts in')}
      </Text>
      <Flex justifyContent="center" gap={{ md: '10px', base: '4px' }} mt={2} alignItems="center">
        {!!countdown.days && (
          <>
            <Tile value={countdown.days} />
            <Text fontSize="22px" fontWeight={700} opacity={0.5} alignSelf="start">
              D
            </Text>
          </>
        )}
        <Tile value={countdown.hours} />
        <Text fontSize="22px" fontWeight={700} opacity={0.5} alignSelf="start">
          H
        </Text>
        <Tile value={countdown.minutes} />
        <Text fontSize="22px" fontWeight={700} opacity={0.5} alignSelf="start">
          M
        </Text>
        <Tile value={countdown.seconds} />
        <Text fontSize="22px" fontWeight={700} opacity={0.5} alignSelf="start">
          S
        </Text>
      </Flex>
    </Flex>
  );
};

const Tile = ({ value }: { value: number }) => {
  const displayValue = value < 10 ? `0${value}` : value;
  return (
    <Flex
      width={{ base: '60px', md: '100px' }}
      height={{ base: '60px', md: '100px' }}
      background="#17255F"
      borderRadius="10px"
      alignItems="center"
      justifyContent="center">
      <Text fontSize={{ base: '16px', md: '64px' }} fontWeight={700}>
        {displayValue}
      </Text>
    </Flex>
  );
};
