import React from 'react';
// import styles from './index.module.scss';
import { Flex, Text } from '@chakra-ui/react';
import { CommonButton } from '../../../components/UI/CommonButton';
import { useTranslation } from 'react-i18next';

export default (props: { isWhiteList: boolean }) => {
  const applyLink = 'https://forms.gle/rYQQA1U8JfosURCY8';
  const learnMoreLink = 'https://medium.com/@insfi';
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" width="100%">
      <Text fontSize={{ base: '32px', md: '46px' }} fontWeight={700}>
        {t('INSFI Whitelist', 'INSFI Whitelist')}
      </Text>
      <Flex flexWrap="wrap" justifyContent="space-between" width="100%" rowGap={4}>
        <Flex flexDirection="column" gap={1} justifyContent="end">
          <Flex rowGap={2}>
            <Text fontSize={{ base: '16px', md: '16px' }}>{t('Whitelist Amount:', 'Whitelist Amount:')}</Text>
            <Text
              fontSize={{ base: '16px', md: '16px' }}
              fontWeight={700}
              color="#F3BA2F"
              ml={{ base: '10px', md: '10px' }}>
              500
            </Text>
          </Flex>
          <Flex flexWrap="wrap">
            <a href={learnMoreLink} target="_blank">
              <Text fontSize={{ base: '16px', md: '16px' }} fontWeight={700} color="#F3BA2F">
                {t('Learn More', 'Learn More')}
              </Text>
            </a>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="20px">
          {!props.isWhiteList && (
            <a href={applyLink} target="_blank">
              <CommonButton handleClick={undefined} name={'Apply'} />
            </a>
          )}

          {!!props.isWhiteList && (
            <Flex rowGap={2} border="1px solid #026918" px="20px" py="10px" borderRadius="10px">
              <Text fontSize={{ base: '12px', md: '16px' }} fontWeight={700}>
                ✅{t('Your’re in the Whitelist', 'Your’re in the Whitelist')}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
