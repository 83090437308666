import { Flex, Text, Image } from '@chakra-ui/react';
import ethIcon from './eth.png';
import { t } from 'i18next';
import { CommonButton } from '../../components/UI/CommonButton';
import styles from './index.module.scss';
import { useZuStore } from '../../hooks/useZuStore';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ethInvestReport, getEthReward } from '../../utils/investReport';

export const EthSection = () => {
  const zuStore = useZuStore();
  const accountAddress = zuStore.userState?.accountAddress;
  const isEthConnected = zuStore.walletInfo?.walletType === 'eth' && !!zuStore.userState?.token;

  const [contribution, setContribution] = useState('-');
  const [earned, setEarned] = useState('-');

  useQuery({
    queryKey: ['eth-contribution', accountAddress],
    queryFn: async () => {
      const contrib = ethInvestReport.find((item) => item.address.toLowerCase() === accountAddress!.toLowerCase());
      setContribution(contrib?.sum.toString() || '0');
      const earnedInfi = getEthReward(accountAddress!);
      setEarned(earnedInfi.toString());
      return null;
    },
    enabled: isEthConnected && !!accountAddress
  });

  const onWalletModalOpen = zuStore.walletModalInfo.onModalOpen;

  // set url query to type=eth and open wallet modal
  const hanleConenct = () => {
    zuStore.reset();
    window.history.replaceState({}, '', '?type=eth');
    onWalletModalOpen();
  };

  return (
    <Flex flexDirection="column" width="370px" className={styles.card} px="20px" py="40px" gap={4}>
      <Image src={ethIcon} width="110px" aspectRatio="auto" />
      <Flex width="100%" justifyContent="space-between" flexDirection={{ base: 'column', md: 'row' }}>
        <LabeledText label={t('Contribution', 'Contribution')} value={contribution} />
        <LabeledText label={t('Earned(INFI)', 'Earned(INFI)')} value={earned} />
        <LabeledText label={t('Claimable(INFI)', 'Claimable(INFI)')} value="-" />
      </Flex>

      {isEthConnected ? (
        <CommonButton handleClick={undefined} name={'Claim'} disabled />
      ) : (
        <CommonButton handleClick={hanleConenct} name={t('Connect', 'Connect')} />
      )}
    </Flex>
  );
};

const LabeledText = ({ label, value }: { label: string; value: string }) => {
  return (
    <Flex flexDirection="column" gap={1} justifyContent="end">
      <Text fontSize={{ base: '16px', md: '16px' }} fontWeight={700}>
        {value}
      </Text>
      <Text fontSize={{ base: '14px', md: '14px' }} color="rgba(255,255,255,0.5)">
        {label}
      </Text>
    </Flex>
  );
};
