import { Flex, Text } from '@chakra-ui/react';
import { t } from 'i18next';
// import { CommonButton } from '../../components/UI/CommonButton';

export const Title = () => {
  return (
    <Flex flexDirection="column" width="100%" padding={'15px'}>
      <Text fontSize={{ base: '32px', md: '46px' }} fontWeight={700}>
        {t('Stake Inscription', 'Stake Inscription')}
      </Text>
    </Flex>
  );
};
