
// Note: Wallet utilities
export const getUnisat = () => {
  if (typeof window.unisat !== 'undefined') {
    return window.unisat;
  }
  return null;
};

export const getOkx = () => {
  if (typeof window.okxwallet !== 'undefined') {
    return window.okxwallet;
  }
  return null;
};

// export const getOkxWalletProvider = () => {
//   if (typeof window.okxwallet !== 'undefined') {
//     return window.okxwallet;
//   }
//   return null;
// };

export const getMetamaskProvider = () => {
  const provider = window.ethereum;
  return provider;
};
// export const getMetamaskProvider = async () => {
//   const provider = await detectEthereumProvider({
//     mustBeMetaMask: true,
//   });
//   return provider;
// };
