import React from 'react';
// import styles from './index.module.scss';
import { Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default () => {
  const navigate = useNavigate();
  const rulesLink = 'https://insfi.medium.com/the-insfi-protocol-is-poised-to-initiate-its-fair-launch-cb78bc2a43dc';
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      alignItems={{ md: 'start', base: 'center' }}
      mb={{ base: '40px', md: 0 }}
      gap={{ md: 0, base: '12px' }}>
      <Text fontSize={{ base: '32px', md: '46px' }} fontWeight={700}>
        {t('Fair Launch', 'Fair Launch')}
      </Text>
      <Flex>
        <a href={rulesLink} target="_blank">
          <Text fontSize={{ base: '16px', md: '16px' }} fontWeight={700} color="#F3BA2F" cursor="pointer">
            {t('The Rule of InsFi Fair Launch', 'The Rule of InsFi Fair Launch')}
          </Text>
        </a>
      </Flex>
      <Flex>
        <Text
          fontSize={{ base: '16px', md: '16px' }}
          fontWeight={700}
          color="#F3BA2F"
          cursor="pointer"
          onClick={() => navigate('/whitelist')}>
          {t('Get WhiteList Now', 'Get WhiteList Now')}
        </Text>
        <Text fontSize={{ base: '16px', md: '16px' }} ml={2}>
          {' '}
          {t('to earn more', 'to earn more')}
        </Text>
      </Flex>
    </Flex>
  );
};
