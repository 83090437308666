import { Flex, Text, Image } from '@chakra-ui/react';
import CommonModal from '../UI/CommonModal';
import styles from './index.module.scss';
import { truncAddress } from '../../utils/utils';
import { CommonInput } from '../UI/CommonInput';
import { useState } from 'react';
import { CommonButton } from '../UI/CommonButton';
import { requestPrepareAuth, requestUserLogin } from '../../utils/api';
import { useMessage } from '../../hooks/useMessage';
// import { useBtcConnectState } from '../../hooks/useBtcConnect';
import BtcIcon from '../../assets/network/btc.png';
import EthIcon from '../../assets/network/eth.png';
import { useZuStore } from '../../hooks/useZuStore';
import { getOkxInfo, getUnisatInfo } from '../../hooks/wallets/btcWallets';
import { useTranslation } from 'react-i18next';

type Props = {
  walletAddress: string;
  hasRegistered: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export default ({ isOpen, onClose, walletAddress, hasRegistered }: Props) => {
  const zuStore = useZuStore();
  const provider = zuStore.providerState.provider;
  const { t } = useTranslation();

  const title = hasRegistered ? t('Login', 'Login') : t('Register', 'Register');
  const message = useMessage();

  const query = new URLSearchParams(window.location.search);

  const [inviteCode, setInviteCode] = useState(query.get('inviteCode') || '');

  // const walletName = userInfoState.userInfo.walletName;
  const walletName = zuStore.walletInfo?.walletName;
  const walletType = zuStore.walletInfo?.walletType;
  const signInType = walletName === 'metamask' ? 'EVM' : 'BTC';
  const networkIcon = walletName === 'metamask' ? EthIcon : BtcIcon;
  const getMessageSigner = async () => {
    console.log('getMessageSigner userInfo', zuStore.userState);

    if (walletName === 'metamask') {
      return async (message: string, walletAddress?: string) => {
        return await provider.request({
          method: 'personal_sign',
          params: [message, walletAddress]
        });
      };
    }
    if (walletName === 'okx' && walletType === 'btc') {
      const okxwallet = await getOkxInfo();
      if (!okxwallet) throw new Error(t('okx wallet not found', 'okx wallet not found'));
      return async (message: string) => {
        return okxwallet.signMessage(message);
      };
    }
    if (walletName === 'okx' && walletType === 'eth') {
    }
    if (walletName === 'unisat') {
      const unisat = await getUnisatInfo();
      if (!unisat) throw new Error(t('Unisat wallet not found', 'Unisat wallet not found'));
      return async (message: string) => {
        return unisat.signMessage(message);
      };
    }
    throw new Error(t('Unsupported wallet', 'Unsupported wallet'));
  };

  const handleLogin = async () => {
    try {
      const prepareAuth = await requestPrepareAuth({ walletAddress });
      const signMessage = await getMessageSigner();
      const signature = await signMessage(prepareAuth.messageToSign, walletAddress);

      const loginResult = await requestUserLogin({
        signedMessage: prepareAuth.messageToSign,
        signature,
        inviteCode,
        signatureType: signInType
      });

      zuStore.setUserState({
        ...loginResult,
        nativeBalance: '0',
        accountAddress: walletAddress
      });

      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Login failed', 'Login failed'));
    }
  };

  return (
    <CommonModal isOpen={isOpen} onClose={onClose} title={title} hideCloseBtn>
      <Flex width="100%" flexDirection="column" gap="20px">
        <Flex className={styles.address}>
          <Image src={networkIcon} width="24px" height="24px" />
          <Text fontWeight={600} ml={4}>
            {truncAddress(walletAddress)}
          </Text>
        </Flex>

        {!hasRegistered && (
          <CommonInput
            placeholder="Invite Code(Optional)"
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
          />
        )}

        <CommonButton handleClick={handleLogin} name={title} />
      </Flex>
    </CommonModal>
  );
};
