import { useToast } from '@chakra-ui/react';

export const useMessage = () => {
  const toast = useToast();

  return {
    info: (message: string) => {
      toast({
        title: message,
        status: 'info',
        duration: 3000,
        isClosable: true
      });
    },
    success: (message: string) => {
      toast({
        title: message,
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    },
    warning: (message: string) => {
      toast({
        title: message,
        status: 'warning',
        duration: 3000,
        isClosable: true
      });
    },
    error: (message: string) => {
      toast({
        title: message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };
};
