import axios from 'axios';
import {
  ApiResponse,
  BtcApiResponse,
  BtcInfoResult,
  HasRegisteredResult,
  InviteesResult,
  LoginParam,
  LoginResult,
  PrepareAuthParam,
  PrepareAuthResult,
  SelfResult
} from './types';

export interface AuthResult {
  walletAddress: string;
  isRegister: boolean;
  nonce: number;
}

const baseUrl = process.env.REACT_APP_API_URL! + '/v1';
const btcUrl = process.env.REACT_APP_BTC_URL! + '/v1';

export const requestGetBtcInfo = async (address: string) => {
  const result = await axios.get<BtcApiResponse<BtcInfoResult>>(`${btcUrl + '/btc/CollectionStatus'}`, {
    params: {
      addr: address
    }
  });
  return result.data.data;
};

export const requestPrepareAuth = async (payload: PrepareAuthParam) => {
  const result = await axios.post<ApiResponse<PrepareAuthResult>>(`${baseUrl + '/accounts/prepareAuth'}`, payload);
  return result.data.data;
};

export const requestUserLogin = async (payload: LoginParam) => {
  const params = { ...payload };
  const hasInviteCode = payload.inviteCode ? true : false;
  if (!hasInviteCode) {
    delete params.inviteCode;
  }
  const result = await axios.post<ApiResponse<LoginResult>>(`${baseUrl + '/accounts/login'}`, params);
  return result.data.data;
};

export const requestHasRegistered = async (walletAddress: string) => {
  const result = await axios.get<ApiResponse<HasRegisteredResult>>(`${baseUrl + '/accounts/hasRegistered'}`, {
    params: {
      address: walletAddress
    }
  });
  return result.data.data;
};

export const requestInvitationCode = async (token: string) => {
  const result = await axios.get<ApiResponse<InviteesResult>>(`${baseUrl + '/accounts/invitees'}`, {
    params: {
      take: 10,
      skip: 0
    },
    headers: {
      Authorization: token
    }
  });
  return result.data.data;
};

export const requestSelf = async (token: string) => {
  const result = await axios.get<ApiResponse<SelfResult>>(`${baseUrl + '/accounts/self'}`, {
    headers: {
      Authorization: token
    }
  });
  return result.data.data;
};

export const requestBtcBalance = async (address: string) => {
  try {
    const url = `https://api.blockcypher.com/v1/btc/main/addrs/${address}/balance`;
    const result = await axios.get(url);
    console.log('result', result);
    
    return result.data['final_balance'];
  } catch (error) {
    return 0;
  }
};
